/** @type Wine[] */
const wines = [
  {
    type: "white",
    name: "Albarino",
    countries: [
      {
        name: "Spain",
        regions: [
          {
            name: "Rias Baixas",
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Chardonnay",
    tasting: {
      oak: "Yes",
      body: "High",
      color: "Gold",
    },
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Burgundy",
            subregions: [
              {
                name: "Chablis",
              },
              {
                name: "Cote d'Or",
              },
              {
                name: "Maconnais",
              },
            ],
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Carneros",
              },
              {
                name: "Central Coast",
              },
              {
                name: "Napa Valley",
              },
              {
                name: "Russian River Valley",
              },
              {
                name: "Sonoma Coast/Valley",
              },
            ],
          },
        ],
      },
      {
        name: "Australia",
        regions: [
          {
            name: "Victoria",
          },
          {
            name: "Padthaway",
          },
          {
            name: "Adelaide Hills",
          },
          {
            name: "Western Australia",
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Chenin Blanc",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Loire",
            subregions: [
              {
                name: "Montlouis",
              },
              {
                name: "Savennieres",
              },
              {
                name: "Vouvray",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Gewurztraminer",
    tasting: {
      sweetness: ["High", "Medium-Plus"],
    },
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Alsace",
            subregions: [
              {
                name: "VT",
              },
              {
                name: "Grand Cru",
              },
              {
                name: "Vin d'Alsace",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Gruner Veltliner",
    tasting: {
      color: ["Straw", "Yellow"],
    },
    countries: [
      {
        name: "Austria",
        regions: [
          {
            name: "Kamptal",
          },
          {
            name: "Kremstal",
          },
          {
            name: "Wachau",
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Pinot Gris",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Alsace",
            subregions: [
              {
                name: "Grand Cru",
              },
              {
                name: "Vin d'Alsace",
              },
            ],
          },
        ],
      },
      {
        name: "Italy",
        regions: [
          {
            name: "Friuli",
          },
          {
            name: "Trentino-Alto Adige",
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "Oregon",
            subregions: [
              {
                name: "Willamette Valley",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Riesling",
    countries: [
      {
        name: "Germany",
        regions: [
          {
            name: "Mosel", // TODO: identify where Trocken, 1st Crus, QbA, Kabinett, Spatlese, Auslese subregions are
          },
          {
            name: "Rheingau",
          },
          {
            name: "Rheinhessen",
          },
          {
            name: "Pfalz",
          },
          {
            name: "Nahe",
          },
        ],
      },
      {
        name: "France",
        regions: [
          {
            name: "Alsace",
            subregions: [
              {
                name: "Grand Cru",
              },
              {
                name: "Vin d'Alsace",
              },
            ],
          },
        ],
      },
      {
        name: "Austria",
        regions: [
          {
            name: "Kamptal",
          },
          {
            name: "Kremstal",
          },
          {
            name: "Wachau",
          },
        ],
      },
      {
        name: "Australia",
        regions: [
          {
            name: "Clare Valley",
          },
          {
            name: "Eden Valley",
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Sauvignon Blanc",
    tasting: {
      aroma: "Tropical Fruit",
      sweetness: ["Dry", "Sweet"],
      viscosity: ["Low", "Medium-Minus"],
      color: ["Watery", "Straw"],
    },
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Loire",
            subregions: [
              {
                name: "Central Vineyards",
              },
            ],
          },
          {
            name: "Bordeaux",
            subregions: [
              {
                name: "Pessac-Leognan",
              },
              {
                name: "Graves",
              },
            ],
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Napa Valley",
              },
              {
                name: "Sonoma Valley",
              },
            ],
          },
        ],
      },
      {
        name: "New Zealand",
        regions: [
          {
            name: "Northland",
          },
          {
            name: "Auckland",
          },
          {
            name: "Waikato/Bay of Plenty",
          },
          {
            name: "Gisborne",
          },
          {
            name: "Hawke's Bay",
          },
          {
            name: "Wellington",
          },
          {
            name: "Nelson",
          },
          {
            name: "Marlborough",
          },
          {
            name: "Cantebury/Waipara",
          },
          {
            name: "Central Otago",
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Torrontes",
    countries: [
      {
        name: "Argentina",
        regions: [
          {
            name: "Salta",
          },
          {
            name: "Catamarca",
          },
        ],
      },
    ],
  },
  {
    type: "white",
    name: "Viognier",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Condrieu",
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Central Coast",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Cabernet Sauvignon",
    tasting: {
      body: ["Medium-Plus", "High"],
      color: ["Garnet (Reddish-Brown)", "Purple", "Brown"],
      oak: "Yes",
      tannin: ["Medium-Plus", "High"],
    },
    countries: [
      {
        name: "France",
        tasting: {
          aroma: ["Floral and Herbal", "Earth"],
        },
        regions: [
          {
            name: "Bordeaux",
            subregions: [
              {
                name: "Graves",
              },
              {
                name: "Medoc",
              },
            ],
          },
        ],
      },
      {
        name: "Australia",
        regions: [
          {
            name: "South Australia",
            subregions: [
              {
                name: "Barossa Valley",
              },
              {
                name: "Coonawarra",
              },
              {
                name: "McLaren Vale",
              },
            ],
          },
          {
            name: "Western Australia",
            subregions: [
              {
                name: "Margaret River",
              },
            ],
          },
        ],
      },
      {
        name: "Chile",
        regions: [
          {
            name: "Central Valley",
            subregions: [
              {
                name: "Curico",
              },
              {
                name: "Maipo",
              },
              {
                name: "Maule",
              },
              {
                name: "Rapel",
              },
            ],
          },
        ],
      },
      {
        name: "USA",
        tasting: {
          aromaIntensity: ["Medium", "Medium-Plus", "High"],
        },
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Napa Valley",
              },
              {
                name: "Sonoma Valley",
              },
              {
                name: "Central Coast",
              },
            ],
          },
          {
            name: "Washington",
            subregions: [
              {
                name: "Columbia Valley",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Cabernet Franc",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Loire Valley",
            subregions: [
              {
                name: "Bourgueil",
              },
              {
                name: "Chinon",
              },
              {
                name: "St. Nicolas de Bourgueil",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Carménère",
    countries: [
      {
        name: "Chile",
        regions: [
          {
            name: "Central Valley",
            subregions: [
              {
                name: "Curico",
              },
              {
                name: "Maipo",
              },
              {
                name: "Maule",
              },
              {
                name: "Rapel",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Corvina",
    countries: [
      {
        name: "Italy",
        regions: [
          {
            name: "Veneto",
            subregions: [
              {
                name: "Amarone",
              },
              {
                name: "Valpolicella",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Gamay Noir",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Beaujolais",
            subregions: [
              {
                name: "Cru Beaujolais",
              },
              {
                name: "Villages",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Grenache",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Rhone Valley",
            subregions: [
              {
                name: "Chateauneuf-du-Pape",
              },
              {
                name: "Gigondas",
              },
              {
                name: "Vacqueyras",
              },
            ],
          },
        ],
      },
      {
        name: "Australia",
        regions: [
          {
            name: "South Australia",
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Malbec",
    tasting: {
      tannin: ["Medium-Plus", "High"],
    },
    countries: [
      {
        name: "Argentina",
        regions: [
          {
            name: "Mendoza",
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Merlot",
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Bordeaux",
            subregions: [
              {
                name: "Pomerol",
              },
              {
                name: "Saint-Emilion",
              },
            ],
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Napa Valley",
              },
              {
                name: "Sonoma Valley",
              },
            ],
          },
          {
            name: "Washington",
            subregions: [
              {
                name: "Columbia Valley",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Nebbiolo",
    tasting: {
      tannin: "High",
    },
    countries: [
      {
        name: "Italy",
        regions: [
          {
            name: "Piedmont",
            subregions: [
              {
                name: "Barolo",
              },
              {
                name: "Barbaresco",
              },
              {
                name: "Gattinara",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Pinot Noir",
    tasting: {
      color: "Garnet (Reddish-Brown)",
      clarity: "Clear",
    },
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Burgundy",
            subregions: [
              {
                name: "Cote d'Or",
              },
            ],
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Anderson Valley",
              },
              {
                name: "Carneros",
              },
              {
                name: "Central Coast",
              },
              {
                name: "Sonoma Valley",
              },
            ],
          },
          {
            name: "Oregon",
            subregions: [
              {
                name: "Willamette Valley",
              },
            ],
          },
        ],
      },
      {
        name: "New Zealand",
        regions: [
          {
            name: "Central Otago",
          },
          {
            name: "Marlborough",
          },
          {
            name: "Wellington/Wairarapa",
            subregions: [
              {
                name: "Martinborough",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Sangiovese",
    tasting: {
      tannin: ["Medium-Plus", "High"],
    },
    countries: [
      {
        name: "Italy",
        regions: [
          {
            name: "Tuscany",
            subregions: [
              {
                name: "Brunello di Montalcino",
              },
              {
                name: "Chianti",
              },
              {
                name: "Vino Nobile di Montepulciano",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Syrah/Shiraz",
    tasting: {
      body: ["Medium-Plus", "High"],
      color: "Purple",
      aroma: value => value.includes("Fruit"),
      acidity: ["Medium-Plus", "High"],
      tannin: ["Medium-Plus", "High"],
    },
    countries: [
      {
        name: "France",
        regions: [
          {
            name: "Rhône Valley",
            subregions: [
              {
                name: "Northern Rhône",
              },
            ],
          },
        ],
      },
      {
        name: "Australia",
        regions: [
          {
            name: "South Australia",
          },
          {
            name: "Victoria",
          },
        ],
      },
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Central Coast",
              },
              {
                name: "Sonoma Valley",
              },
            ],
          },
          {
            name: "Washington",
            subregions: [
              {
                name: "Columbia Valley",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Tempranillo",
    countries: [
      {
        name: "Spain",
        regions: [
          {
            name: "Ribera del Duero",
          },
          {
            name: "Rioja",
          },
        ],
      },
    ],
  },
  {
    type: "red",
    name: "Zinfandel",
    countries: [
      {
        name: "USA",
        regions: [
          {
            name: "California",
            subregions: [
              {
                name: "Napa Valley",
              },
              {
                name: "Paso Robles",
              },
              {
                name: "Sonoma Valley",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default wines;
