import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { ControlLabel } from "react-bootstrap";
import VarietalsAutocomplete from "../helpers/varietals-autocomplete";
import CountriesAutocomplete from "../helpers/countries-autocomplete";
import RegionsAutocomplete from "../helpers/regions-autocomplete";
import { shouldShowAdvancedFields } from "../modules/selectors";
import { connect } from "react-redux";
import TastingGrid from "./components/tasting-grid";

const ConclusionForm = props => (
  <form>
    {props.showTastingGrid && <TastingGrid form={props.form} />}
    {props.showAdvanced && (
      <div>
        <ControlLabel>Age</ControlLabel>
        <Field name="age" component="select" className="form-control">
          <option />
          <option value="1-3">1-3</option>
          <option value="3-5">3-5</option>
          <option value="5-10">5-10</option>
          <option value="10+">10+</option>
        </Field>
      </div>
    )}
    {props.showAdvanced && (
      <div>
        <ControlLabel>Vintage</ControlLabel>
        <Field
          name="vintage"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
    )}
    <ControlLabel>Primary Varietal</ControlLabel>
    <VarietalsAutocomplete
      name="varietals"
      selectedVarietal={props.selectedVarietal}
    />
    <ControlLabel>Country</ControlLabel>
    <CountriesAutocomplete
      name="country"
      selectedVarietal={props.selectedVarietal}
      selectedCountry={props.selectedCountry}
    />
    <ControlLabel>Region</ControlLabel>
    <RegionsAutocomplete
      name="region"
      selectedVarietal={props.selectedVarietal}
      selectedCountry={props.selectedCountry}
      selectedRegion={props.selectedRegion}
    />
    {props.showAdvanced && (
      <div>
        <ControlLabel>Sub-Region</ControlLabel>
        <Field
          name="subregion"
          component="input"
          type="text"
          className="form-control"
        />
        <ControlLabel>Quality Level</ControlLabel>
        <Field
          name="quality"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
    )}
    <ControlLabel>Observations</ControlLabel>
    <Field name="observations" component="textarea" className="form-control" />
    <hr />
  </form>
);

const ConnectedConclusionForm = reduxForm({
  destroyOnUnmount: false,
})(ConclusionForm);

const ConnectedConclusionFormWithAddedProps = connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.form);
  return {
    selectedVarietal: selector(state, "varietals"),
    selectedCountry: selector(state, "country"),
    selectedRegion: selector(state, "region"),
    showAdvanced: shouldShowAdvancedFields(state),
  };
})(ConnectedConclusionForm);

export default ConnectedConclusionFormWithAddedProps;
