import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { ControlLabel } from "react-bootstrap";
import VarietalsAutocomplete from "../helpers/varietals-autocomplete";
import CountriesAutocomplete from "../helpers/countries-autocomplete";
import RegionsAutocomplete from "../helpers/regions-autocomplete";
import { shouldShowAdvancedFields } from "../modules/selectors";
import { get } from "lodash";
import TastingGrid from "./components/tasting-grid";

const ResultsForm = props => {
  const onCheckboxClick = e => {
    const fieldName = e.target.name.replace("Checked", "");
    let changeToValue = "";
    if (e.target.checked) {
      changeToValue = props.conclusionsData[fieldName];
    }
    props.change(fieldName, changeToValue);
  };

  const CheckboxField = props => (
    <Field
      component="input"
      type="checkbox"
      onClick={onCheckboxClick}
      {...props}
    />
  );

  const getOtherableField = name => {
    const mainValue = props.conclusionsData[name];
    if (mainValue === "other") {
      return props.conclusionsData[`${name}-other`];
    }
    return mainValue;
  };

  const renderVintage = props => (
    <div>
      <ControlLabel>Vintage</ControlLabel>
      <div>
        <CheckboxField name="vintageChecked" />
        <span>You put: {props.conclusionsData["vintage"]}</span>
      </div>
      <Field
        name="vintage"
        component="input"
        type="text"
        className="form-control"
        disabled={props.eventCodeEntered || props.vintageChecked}
      />
    </div>
  );

  const renderOtherAdvanced = props => (
    <div>
      <ControlLabel>Sub-Region</ControlLabel>
      <div>
        <CheckboxField name="subregionChecked" />
        <span>You put: {props.conclusionsData["subregion"]}</span>
      </div>
      <Field
        name="subregion"
        component="input"
        type="text"
        className="form-control"
        disabled={props.eventCodeEntered || props.subregionChecked}
      />
      <ControlLabel>Quality Level (Not scored)</ControlLabel>
      <div>You put: {props.conclusionsData["quality"]}</div>
    </div>
  );

  const renderAge = props => (
    <div>
      <ControlLabel>Age</ControlLabel>
      <div>
        <CheckboxField name="ageChecked" />
        <span>You put: {props.conclusionsData["age"]}</span>
      </div>
      <Field
        name="age"
        component="select"
        className="form-control"
        disabled={props.eventCodeEntered || props.ageChecked}
      >
        <option />
        <option value="1-3">1-3</option>
        <option value="3-5">3-5</option>
        <option value="5-10">5-10</option>
        <option value="10+">10+</option>
      </Field>
    </div>
  );

  return (
    <form>
      <TastingGrid form={props.form} conclusionsData={props.conclusionsData} />
      {props.showAdvanced && renderAge(props)}
      {props.showAdvanced && renderVintage(props)}
      <ControlLabel>Primary Varietal</ControlLabel>
      <div>
        <CheckboxField name="varietalsChecked" />
        <span>You put: {getOtherableField("varietals")}</span>
      </div>
      <VarietalsAutocomplete
        name="varietals"
        selectedVarietal={props.selectedVarietal}
        disabled={props.eventCodeEntered || props.varietalsChecked}
      />
      <ControlLabel>Country</ControlLabel>
      <div>
        <CheckboxField name="countryChecked" />
        <span>You put: {getOtherableField("country")}</span>
      </div>
      <CountriesAutocomplete
        name="country"
        disabled={props.eventCodeEntered || props.countryChecked}
        selectedVarietal={props.selectedVarietal}
        selectedCountry={props.selectedCountry}
      />
      <ControlLabel>Region</ControlLabel>
      <div>
        <CheckboxField name="regionChecked" />
        <span>You put: {getOtherableField("region")}</span>
      </div>
      <RegionsAutocomplete
        name="region"
        selectedVarietal={props.selectedVarietal}
        selectedCountry={props.selectedCountry}
        selectedRegion={props.selectedRegion}
        disabled={props.eventCodeEntered || props.regionChecked}
      />
      {props.showAdvanced && renderOtherAdvanced(props)}
      <ControlLabel>Other Observations (Not scored)</ControlLabel>
      <div>You put: {props.conclusionsData["observations"]}</div>
      <ControlLabel>Lessons Learned (Not scored)</ControlLabel>
      <Field
        name="observations"
        component="textarea"
        className="form-control"
      />
      <hr />
    </form>
  );
};

const ConnectedResultsForm = reduxForm({
  destroyOnUnmount: false,
})(ResultsForm);

const ConnectedResultsFormWithSelector = connect(
  (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    return {
      ageChecked: selector(state, "ageChecked"),
      vintageChecked: selector(state, "vintageChecked"),
      varietalsChecked: selector(state, "varietalsChecked"),
      countryChecked: selector(state, "countryChecked"),
      regionChecked: selector(state, "regionChecked"),
      subregionChecked: selector(state, "subregionChecked"),
      selectedVarietal: selector(state, "varietals"),
      selectedCountry: selector(state, "country"),
      selectedRegion: selector(state, "region"),
      showAdvanced: shouldShowAdvancedFields(state),
      eventCodeEntered: !!get(state, "form.general.values.eventCode"),
    };
  },
  () => {
    return {
      change,
    };
  },
)(ConnectedResultsForm);

export default ConnectedResultsFormWithSelector;
