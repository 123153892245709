import React from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import GeneralForm from "../../forms/general";
import { Button } from "react-bootstrap";
import EnterWines from "../../helpers/enter-wines";
import { get } from "lodash";
import { getEventResults } from "../../modules/db/event/actions";
import BUTTON_STATE from "../../helpers/button-state";

const Entry = props => {
  const onResultsClick = () => {
    props.shouldGetEventResults ? props.getEventResults() : props.changePage();

    // Prompt the user to install the PWA on their home screen
    if (window.deferredPrompt) {
      window.deferredPrompt.prompt();
    }
  };
  return (
    <div>
      <EnterWines formPrefix="conclusions" showTastingGrid />
      <div className="topAndBottomMargin">
        <GeneralForm />
      </div>
      <div>
        <Button bsStyle="primary" onClick={onResultsClick}>
          {props.buttonText}
        </Button>
        {props.eventResultsResponse}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  shouldGetEventResults: !!get(state, "form.general.values.eventCode"),
  buttonText:
    get(state, "db.event.eventFetch.buttonState") === BUTTON_STATE.LOADING
      ? "Loading"
      : get(state, "form.general.values.eventCode")
        ? "View Results"
        : "Enter Results",
  eventResultsResponse: get(state, "db.event.eventFetch.responseMessage"),
});

const mapDispatchToProps = {
  changePage: () => push("/results"),
  getEventResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
