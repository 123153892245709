import BUTTON_STATE from "./button-state";

const initialState = {
  responseMessage: null,
  buttonState: BUTTON_STATE.NOTHING,
};

/**
 *
 * @param {string} key The prefix for the actions to process
 */
const createReducer = key => (state = initialState, action) => {
  switch (action.type) {
    case `${key}_LOADING`:
      return {
        ...state,
        buttonState: BUTTON_STATE.LOADING,
      };
    case `${key}_COMPLETE`:
      return {
        ...state,
        buttonState: action.payload.err
          ? BUTTON_STATE.ERROR
          : BUTTON_STATE.SUCCESS,
        responseMessage: action.payload.err ? action.payload.err : null,
      };
    case `${key}_RESET`:
      return {
        ...state,
        buttonState: BUTTON_STATE.NOTHING,
        responseMessage: null,
      };
    default:
      return state;
  }
};

export default createReducer;
