import { getDynamoDb } from "./selectors";
import { flatMap } from "lodash";

export const assignFieldIfPresent = (fieldName, obj, Item, fieldType = "S") => {
  if (obj) {
    Item[fieldName] = {
      [fieldType]: String(obj),
    };
  }
};

export const getFieldsFromForm = (form, fieldPrefix, index = -1) => {
  return (
    (form &&
      form.values &&
      Object.keys(form.values)
        .filter(key => !key.includes("other")) //filter out 'other' keys since we capture them as part of the main value below
        .map(key => {
          const wineIndex = index !== -1 ? `_Wine${index}` : "";
          const value = form.values[key];
          return {
            fieldName: `${fieldPrefix}${wineIndex}_${key}`,
            obj: value === "other" ? form.values[`${key}-other`] : value,
          };
        })) ||
    []
  );
};

export const getFieldsFromArray = (formArray, fieldPrefix) => {
  return flatMap(formArray, (form, index) => {
    return getFieldsFromForm(form, fieldPrefix, index);
  });
};

export const createSaveToDbFunc = (
  createItem,
  saveLoading,
  saveComplete,
) => () => {
  return async (dispatch, getState) => {
    dispatch(saveLoading());

    const dynamoDb = await getDynamoDb(dispatch, getState);

    dynamoDb.putItem(
      createItem(getState(), dynamoDb.config.credentials.identityId),
      (err, data) => {
        dispatch(saveComplete(err, data));
      },
    );
  };
};
