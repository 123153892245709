import { getDynamoDb } from "../selectors";
import {
  assignFieldIfPresent,
  getFieldsFromForm,
  getFieldsFromArray,
  createSaveToDbFunc,
} from "../util";
import { get } from "lodash";
import { RESULTS_TABLE_NAME } from "../../../helpers/constants";
import { createLeaderboardItem } from "../leaderboard/actions";
import { shouldPostToLeaderboard } from "../../selectors";

const saveLoading = () => {
  return {
    type: "RESULTS_SAVE_LOADING",
  };
};

export const resultsSaveReset = () => {
  return {
    type: "RESULTS_SAVE_RESET",
  };
};

const saveComplete = (err, data) => {
  return {
    type: "RESULTS_SAVE_COMPLETE",
    payload: {
      err,
      data,
    },
  };
};

const createItem = (state, userId) => {
  const Item = {
    UserId: {
      S: userId,
    },
  };

  const fields = [
    { fieldName: "TastingDateTime", obj: Date.now(), fieldType: "N" },
  ]
    .concat(getFieldsFromForm(get(state, "form.general"), "general"))
    .concat(getFieldsFromArray(get(state, "form.conclusions"), "conclusions"))
    .concat(getFieldsFromArray(get(state, "form.results"), "results"));

  fields.forEach(field =>
    assignFieldIfPresent(field.fieldName, field.obj, Item, field.fieldType),
  );

  return {
    Item,
    TableName: RESULTS_TABLE_NAME,
  };
};

export const saveResults = () => (dispatch, getState) => {
  createSaveToDbFunc(createItem, saveLoading, saveComplete)()(
    dispatch,
    getState,
  );

  shouldPostToLeaderboard(getState()) &&
    createSaveToDbFunc(createLeaderboardItem, saveLoading, saveComplete)()(
      dispatch,
      getState,
    );
};

/** --- HISTORY ACTIONS --- */

const getHistoryLoading = () => {
  return {
    type: "GET_HISTORY_LOADING",
  };
};

const getHistoryComplete = (err, data) => {
  return {
    type: "GET_HISTORY_COMPLETE",
    payload: {
      err,
      data,
    },
  };
};

export const getHistory = () => {
  return async (dispatch, getState) => {
    if (getState().auth.attributes) {
      dispatch(getHistoryLoading());

      const dynamoDb = await getDynamoDb(dispatch, getState);

      var params = {
        ExpressionAttributeValues: {
          ":v1": {
            S: dynamoDb.config.credentials.identityId,
          },
        },
        KeyConditionExpression: "UserId = :v1",
        TableName: RESULTS_TABLE_NAME,
        ScanIndexForward: false, // show most recent dates first
      };

      dynamoDb.query(params, function(err, data) {
        dispatch(getHistoryComplete(err, data));
      });
    } else {
      dispatch(getHistoryComplete(null, { Items: [] }));
    }
  };
};
