import BUTTON_STATE from "../../../helpers/button-state";

const initialState = {
  responseMessage: null,
  buttonState: BUTTON_STATE.NOTHING,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LEADERBOARD_LOADING":
      return {
        ...state,
        buttonState: BUTTON_STATE.LOADING,
      };
    case "GET_LEADERBOARD_COMPLETE":
      return {
        ...state,
        buttonState: action.payload.err
          ? BUTTON_STATE.ERROR
          : BUTTON_STATE.SUCCESS,
        responseMessage: action.payload.err
          ? JSON.stringify(action.payload.err)
          : null,
        data: action.payload.data ? action.payload.data.Items : [],
      };
    default:
      return state;
  }
};

export default reducer;
