import React from "react";
import wines from "./wine-names";
import SelectWithOther from "./select-with-other";
import { flatMapDeep } from "lodash";

const fullListOfRegions = flatMapDeep(
  wines.map(
    wine =>
      wine.countries
        ? wine.countries.map(
            country =>
              country.regions ? country.regions.map(region => region.name) : [],
          )
        : [],
  ),
);

const RegionsAutocomplete = ({
  name,
  disabled,
  selectedVarietal,
  selectedCountry,
  selectedRegion,
}) => {
  const wine = wines.find(wine => wine.name === selectedVarietal);
  const countriesListToUse = wine
    ? wine.countries
    : flatMapDeep(wines.map(wine => (wine.countries ? wine.countries : [])));
  const countries = countriesListToUse.filter(
    country => country.name === selectedCountry,
  );

  const regionNamesInSelectedCountry =
    countries.length > 0
      ? flatMapDeep(
          countries.map(country => country.regions.map(region => region.name)),
        )
      : [];

  return (
    <SelectWithOther
      name={name}
      disabled={disabled}
      namedFieldValue={selectedRegion}
      selectOptions={regionNamesInSelectedCountry}
      autocompleteOptions={fullListOfRegions}
    />
  );
};

export default RegionsAutocomplete;
