import { CognitoAuth } from "amazon-cognito-auth-js/dist/amazon-cognito-auth";
import { COGNITO_USER_POOL_CLIENT_ID } from "./constants";

const baseUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? ":" + window.location.port : ""
}/login`;

const authData = {
  ClientId: COGNITO_USER_POOL_CLIENT_ID,
  AppWebDomain: "blindtaste.auth.us-east-1.amazoncognito.com",
  TokenScopesArray: ["email", "openid", "aws.cognito.signin.user.admin"],
  RedirectUriSignIn: baseUrl,
  RedirectUriSignOut: baseUrl,
};
const auth = new CognitoAuth(authData);
auth.useCodeGrantFlow();

export const login = () => {
  auth.getSession();
};

export const parseUrl = () => {
  return new Promise((resolve, reject) => {
    auth.userhandler = {
      onSuccess: function(result) {
        resolve(result);
      },
      onFailure: function(err) {
        reject(err);
      },
    };

    const curUrl = window.location.href;
    auth.parseCognitoWebResponse(curUrl);
  });
};

export const logout = () => {
  localStorage.clear();
  auth.signOut();
};

export default login;
