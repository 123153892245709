import React from "react";
import { Field } from "redux-form";
import ReduxFormAutocomplete from "./redux-form-autocomplete";

const SelectWithOther = props => {
  const {
    name,
    namedFieldValue,
    selectOptions,
    autocompleteOptions,
    disabled,
  } = props;
  const selectField = (
    <Field
      name={name}
      component="select"
      className="form-control"
      disabled={disabled}
    >
      <option />
      {selectOptions.map(name => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
      <option value="other">Other</option>
    </Field>
  );

  const ReduxFormAutocompleteComponent = props => (
    <ReduxFormAutocomplete
      {...props}
      items={autocompleteOptions}
      minCharacters={2}
    />
  );

  return (
    <div>
      {selectField}
      {namedFieldValue === "other" && (
        <Field
          name={`${name}-other`}
          component={ReduxFormAutocompleteComponent}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default SelectWithOther;
