import React from "react";
import { connect } from "react-redux";
import { Button, ListGroupItem } from "react-bootstrap";
import ResultsForm from "../../forms/results";
import { ControlLabel } from "react-bootstrap";
import { saveResults } from "../../modules/db/results/actions";
import BUTTON_STATE from "../../helpers/button-state";
import { times } from "lodash";
import LoginButton from "../../helpers/login-button";
import SaveSuccessModal from "./save-success-modal";
import LeaderboardNameForm from "../../forms/leaderboard-name";
import {
  getValuesOrEmptyObject,
  points,
  sumOfPoints,
} from "../../helpers/calculate-points";

const renderResultsForms = (numberOfWines, conclusions, results) =>
  times(numberOfWines, index => {
    const conclusionsValues = getValuesOrEmptyObject(conclusions[index]);
    const resultsValues = getValuesOrEmptyObject(results[index]);

    return (
      <div key={`results-form-wrapper-${index}`}>
        <h3>Wine {index + 1}</h3>
        <ResultsForm
          form={`results[${index}]`}
          conclusionsData={conclusionsValues}
        />
        <ListGroupItem bsStyle="success">
          Points: <strong>{points(conclusionsValues, resultsValues)}</strong>
        </ListGroupItem>
      </div>
    );
  });

const Results = props => (
  <div>
    {renderResultsForms(props.numberOfWines, props.conclusions, props.results)}
    <hr />
    <ControlLabel>General Tasting Notes</ControlLabel>
    <div>
      {props.general &&
        props.general.values &&
        props.general.values.tastingNotes}
    </div>
    <div className="topAndBottomMargin">
      <ListGroupItem bsStyle="success">
        Total Points: <strong>{sumOfPoints(props)}</strong>
      </ListGroupItem>
    </div>
    <div>
      {props.isLoggedIn ? (
        <div>
          <div className="topAndBottomMargin">
            <LeaderboardNameForm />
          </div>
          <Button
            bsStyle="primary"
            onClick={props.saveResults}
            disabled={props.isSaveButtonDisabled}
          >
            {props.buttonState}
          </Button>
        </div>
      ) : (
        <LoginButton />
      )}
    </div>
    {props.responseMessage && <div>{props.responseMessage}</div>}
    {props.buttonState === BUTTON_STATE.SUCCESS && <SaveSuccessModal />}
  </div>
);

const mapStateToProps = state => ({
  numberOfWines: state.display.numberOfWines,
  conclusions: state.form.conclusions || [{}],
  results: state.form.results || [{}],
  general: state.form.general || {},
  responseMessage: state.db.results.responseMessage,
  isLoggedIn: !!state.auth.attributes,
  buttonState: state.db.results.buttonState,
  isSaveButtonDisabled:
    [BUTTON_STATE.LOADING].includes(state.db.results.buttonState) ||
    state.auth.skipLogin,
});

const mapDispatchToProps = {
  saveResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
