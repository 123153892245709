import { combineReducers } from "redux";

import event from "./event/reducer";
import results from "./results/reducer";
import leaderboard from "./leaderboard/reducer";

export default combineReducers({
  event,
  results,
  leaderboard,
});
