import React from "react";
import { ControlLabel } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import EventCodeInput from "../helpers/event-code-input";

const GeneralForm = props => {
  return (
    <form>
      <ControlLabel>General Tasting Notes</ControlLabel>
      <Field
        name="tastingNotes"
        component="textarea"
        className="form-control"
      />
      <ControlLabel>Event Code (optional)</ControlLabel>
      <Field name="eventCode" component={EventCodeInput} />
    </form>
  );
};

const ReduxGeneralForm = reduxForm({
  form: "general",
  destroyOnUnmount: false,
})(GeneralForm);

export default ReduxGeneralForm;
