import React from "react";
import { connect } from "react-redux";
import ConclusionForm from "../forms/conclusion";
import { times } from "lodash";
import { addWine, removeWine } from "../modules/display/actions";
import { MAX_NUMBER_OF_WINES } from "../helpers/constants";
import { Button } from "react-bootstrap";

const renderConclusionForms = (
  numberOfWines,
  formPrefix,
  showTastingGrid = false,
) =>
  times(numberOfWines, index => (
    <div key={`${formPrefix}-form-wrapper-${index}`}>
      <h3>Wine {index + 1}</h3>
      <ConclusionForm
        form={`${formPrefix}[${index}]`}
        showTastingGrid={showTastingGrid}
      />
    </div>
  ));

const EnterWines = props => (
  <div>
    {renderConclusionForms(
      props.numberOfWines,
      props.formPrefix,
      props.showTastingGrid,
    )}
    <div>
      <div>
        {props.numberOfWines > 1 && (
          <Button onClick={props.removeWine}>- Remove Wine</Button>
        )}
      </div>
      <div>
        {props.numberOfWines < MAX_NUMBER_OF_WINES ? (
          <Button onClick={props.addWine}>+ Add Wine</Button>
        ) : (
          "Max of 10 wines reached"
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  numberOfWines: state.display.numberOfWines,
});

export default connect(mapStateToProps, { addWine, removeWine })(EnterWines);
