import React from "react";
import wines from "./wine-names";
import fullCountryNames from "./country-names";
import SelectWithOther from "./select-with-other";
import { isEqual, uniqWith } from "lodash";
import { allTestableCountryNames } from "../modules/selectors";

const CountriesAutocomplete = ({
  name,
  disabled,
  selectedVarietal,
  selectedCountry,
}) => {
  const wine = wines.find(wine => wine.name === selectedVarietal);
  const countryNames = wine
    ? wine.countries.map(country => country.name)
    : allTestableCountryNames;

  // Concat rest of countries on the end so the countries commonly matched to the wine are listed first
  // Use lodash to remove countries already present in the first list
  const fullList = uniqWith(
    countryNames.concat(fullCountryNames.map(country => country.name)),
    isEqual,
  );

  return (
    <SelectWithOther
      name={name}
      disabled={disabled}
      namedFieldValue={selectedCountry}
      selectOptions={countryNames}
      autocompleteOptions={fullList}
    />
  );
};

export default CountriesAutocomplete;
