import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createHistory from "history/createBrowserHistory";
import rootReducer from "./modules";
import ReactGA from "react-ga";

export const history = createHistory();

// See https://github.com/ReactTraining/react-router/issues/4278
ReactGA.initialize("UA-168218-6");
history.listen((location, action) => {
  ReactGA.pageview(location.pathname);
});

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === "function") {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;
