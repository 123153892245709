import React from "react";
import wineNames from "./wine-names";
import wineNamesAdvanced from "./wine-names-advanced";
import SelectWithOther from "./select-with-other";

const VarietalsAutocomplete = ({ name, disabled, selectedVarietal }) => {
  return (
    <SelectWithOther
      name={name}
      disabled={disabled}
      namedFieldValue={selectedVarietal}
      selectOptions={wineNames.map(wine => wine.name).sort()}
      autocompleteOptions={wineNamesAdvanced.map(wine => wine.name)}
    />
  );
};

export default VarietalsAutocomplete;
