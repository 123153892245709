import React from "react";
import Autocomplete from "react-autocomplete";

const matchStateToTerm = (state, value) => {
  return (
    state.toLowerCase().indexOf(value.toLowerCase()) !== -1 &&
    state.toLowerCase() !== value.toLowerCase() //hide exact matches
  );
};

const ReduxFormAutocomplete = props => {
  return (
    <Autocomplete
      {...props.input}
      getItemValue={item => item}
      inputProps={{
        className: "form-control",
        onFocus: props.input.onFocus,
        disabled: props.disabled,
        placeholder: "Please specify...",
      }}
      wrapperStyle={{ display: "block" }}
      items={props.items}
      renderMenu={children => <div className="menu">{children}</div>}
      renderItem={(item, isHighlighted) => (
        <div
          className={`item ${isHighlighted ? "item-highlighted" : ""}`}
          key={item}
        >
          {item}
        </div>
      )}
      open={
        props.meta.active &&
        (props.minCharacters > 0
          ? !!props.input.value &&
            props.input.value.length >= props.minCharacters
          : true)
      }
      onSelect={props.input.onChange}
      shouldItemRender={matchStateToTerm}
    />
  );
};

export default ReduxFormAutocomplete;
