export const MAX_NUMBER_OF_WINES = 10;

export const COGNITO_USER_POOL_CLIENT_ID =
  process.env.NODE_ENV === "production"
    ? "5frfpg8a6v1466mqoignar8r1b"
    : "3d2i9510ndo5ro3mr79qgolu0l";

export const COGNITO_USER_POOL_ID = "us-east-1_RuQdyhxy7";

export const LOCAL_STORAGE_FORM_KEY = "form-before-login";

export const RESULTS_TABLE_NAME = "BlindTasteResults";
export const EVENTS_TABLE_NAME = "BlindTasteEvents";
export const LEADERBOARD_TABLE_NAME = "BlindTasteLeaderboard";
