import { CognitoUserPool } from "amazon-cognito-identity-js";
import { COGNITO_USER_POOL_CLIENT_ID, COGNITO_USER_POOL_ID } from "./constants";
import AWS from "aws-sdk";

const setupAwsConfigCredentials = accessToken => {
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(
    {
      IdentityPoolId: "us-east-1:675618ab-c17d-40cb-8471-7b51c9e0766d",
      Logins: accessToken && {
        [`cognito-idp.us-east-1.amazonaws.com/${
          COGNITO_USER_POOL_ID
        }`]: accessToken,
      },
    },
    { region: "us-east-1" },
  );

  return new Promise((resolve, reject) => {
    AWS.config.credentials.refresh(error => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export const getCurrentUser = () => {
  const userPool = new CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_USER_POOL_CLIENT_ID,
  });

  const cognitoUser = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        }

        cognitoUser.getUserAttributes(async (err, attributes) => {
          if (err) {
            reject(err);
          } else {
            try {
              await setupAwsConfigCredentials(
                session.getIdToken().getJwtToken(),
              );
            } catch (setupCredsErr) {
              reject(setupCredsErr);
            }
            resolve(attributes);
          }
        });
      });
    } else {
      setupAwsConfigCredentials(); //Use anonymous credentials if not logged in
    }
  });
};
