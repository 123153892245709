import { LOCAL_STORAGE_FORM_KEY } from "../../helpers/constants";

export const saveFormStateToLocalStorage = () => {
  return (dispatch, getState) => {
    window.localStorage.setItem(
      LOCAL_STORAGE_FORM_KEY,
      JSON.stringify(getState().form),
    );
  };
};

export const restoreFormStateFromLocalStorage = () => {
  const formState = JSON.parse(
    window.localStorage.getItem(LOCAL_STORAGE_FORM_KEY),
  );
  return {
    type: "RESTORE_FORM_STATE",
    payload: {
      formState,
    },
  };
};

export const resetFormState = () => ({
  type: "RESET_FORM_STATE",
});
