const initialState = {
  attributes: null,
  skipLogin: false,
  loginException: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_FAILED":
      return {
        ...state,
        loginException: action.payload.exception,
      };
    case "LOGIN_RESPONSE":
      return {
        ...state,
        attributes: action.payload.attributes,
        loginException: null,
      };
    case "SKIP_LOGIN":
      return {
        ...state,
        skipLogin: true,
      };
    default:
      return state;
  }
};

export default reducer;
