import React from "react";
import Login from "../login";
import Entry from "../entry";
import Results from "../results";
import Analyze from "../analyze";
import History from "../analyze/history";
import BlindPurchase from "../blind-purchase";
import Header from "./header";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Redirect } from "react-router-dom";
import Subscribe from "../subscribe";
import { restoreUserFromStorage } from "../../modules/auth/actions";
import { push } from "react-router-redux";
import Events from "../events";
import CreateEvent from "../events/create-event";

const ProtectedRoute = ({
  component: Component,
  isAccessible,
  redirectToPath,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return isAccessible ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: redirectToPath,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const LoggedOutRoute = props => (
  <ProtectedRoute
    isAccessible={!props.isLoggedIn && props.currentPath === props.path}
    redirectToPath={"/entry"}
    path={props.path}
    component={props.component}
  />
);

class App extends React.Component {
  componentDidMount() {
    this.props.restoreUserFromStorage();
    this.props.currentPath === "/" && this.props.push("/entry");
  }
  render() {
    const props = this.props;

    // If routing gets really complex we can put subroutes in each component, but this seems fine for now
    // https://reacttraining.com/react-router/web/example/basic
    const renderMain = () => (
      <main>
        <LoggedOutRoute
          exact
          isLoggedIn={props.isLoggedIn}
          path="/login"
          component={Login}
          redirectToPath={props.previousPath}
          currentPath={props.currentPath}
        />
        <Route exact path="/entry" component={Entry} />
        <Route exact path="/results" component={Results} />
        <Route exact path="/analyze" component={Analyze} />
        <Route exact path="/history" component={History} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/create-event" component={CreateEvent} />
        <Route exact path="/blind-purchase" component={BlindPurchase} />
        <Redirect from="*" to="/entry" />
      </main>
    );

    return (
      <div className="app">
        <Header />
        {renderMain()}
        <hr />
        <Subscribe />
        <hr />
        <div>
          <a href="/events">Setup an event</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedInOrSkipped: !!state.auth.attributes || state.auth.skipLogin,
  isLoggedIn: !!state.auth.attributes,
  currentPath: state.router.location.pathname,
  previousPath:
    state.router &&
    state.router.location &&
    state.router.location.state &&
    state.router.location.state.from &&
    state.router.location.state.from.pathname,
});

export default withRouter(
  connect(mapStateToProps, { push, restoreUserFromStorage })(App),
);
