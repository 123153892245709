import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";

const Leaderboard = props => {
  if (props.data && props.data.length > 0) {
    const columns = [
      {
        Header: "Name",
        id: "name",
        accessor: "LeaderboardName.S",
      },
      {
        Header: "Points",
        id: "points",
        accessor: "Points.S",
      },
    ];

    return (
      <ReactTable
        data={props.data}
        columns={columns}
        defaultSort={{ column: "Points", direction: "desc" }}
      />
    );
  }

  return <div>No results found for that event code!</div>;
};

export default connect(state => ({
  data: state.db.leaderboard.data,
}))(Leaderboard);
