import React from "react";
import { ControlLabel } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { isEventMode } from "../modules/selectors";

const LeaderboardNameForm = props => {
  return (
    <form>
      {props.shouldShowLeaderboardName && (
        <div>
          <ControlLabel>
            Display name for event leaderboard (optional)
          </ControlLabel>
          <Field
            name="name"
            className="form-control"
            component="input"
            maxLength="40"
          />
        </div>
      )}
    </form>
  );
};

const ReduxLeaderboardNameForm = reduxForm({
  form: "leaderboardName",
  destroyOnUnmount: false,
})(LeaderboardNameForm);

const ConnectedLeaderboardNameForm = connect(state => ({
  shouldShowLeaderboardName: isEventMode(state),
}))(ReduxLeaderboardNameForm);

export default ConnectedLeaderboardNameForm;
