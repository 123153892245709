import React from "react";
import EventForm from "../../forms/event";
import EnterWines from "../../helpers/enter-wines";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import BUTTON_STATE from "../../helpers/button-state";
import { saveEvent } from "../../modules/db/event/actions";
import SaveSuccessModal from "./save-success-modal";
import { get } from "lodash";

const renderDisplayMessage = responseMessage => {
  if (responseMessage.code === "ConditionalCheckFailedException") {
    return "Event Code already exists - please try a different one.";
  } else {
    return responseMessage.message;
  }
};

const CreateEvent = props => (
  <div>
    <h1>Create Event</h1>
    <EventForm />
    <EnterWines formPrefix="eventWines" />
    <hr />
    <div>
      <Button
        bsStyle="primary"
        onClick={props.saveEvent}
        disabled={props.isSaveButtonDisabled}
      >
        {props.buttonState}
      </Button>
    </div>
    {props.responseMessage && (
      <div>{renderDisplayMessage(props.responseMessage)}</div>
    )}
    {props.buttonState === BUTTON_STATE.SUCCESS && <SaveSuccessModal />}
  </div>
);

const mapStateToProps = state => ({
  buttonState: state.db.event.eventSave.buttonState,
  responseMessage: state.db.event.eventSave.responseMessage,
  isSaveButtonDisabled:
    [BUTTON_STATE.LOADING].includes(state.db.event.eventSave.buttonState) ||
    state.auth.skipLogin ||
    !get(state, "form.event.values.eventCode"),
});

export default connect(mapStateToProps, {
  saveEvent,
})(CreateEvent);
