import React from "react";
import { Link } from "react-router-dom";
import LeaderboardCodeForm from "../../forms/leaderboard-code";
import { Button } from "react-bootstrap";
import Leaderboard from "./leaderboard";
import { getLeaderboard } from "../../modules/db/leaderboard/actions";
import { connect } from "react-redux";
import BUTTON_STATE from "../../helpers/button-state";

const Events = props => (
  <div>
    <h1>Events Page</h1>
    <Link to="/events/create-event">Create Event</Link>
    <LeaderboardCodeForm />
    <div className="topAndBottomMargin">
      <Button bsStyle="primary" onClick={props.getLeaderboard}>
        View Leaderboard
      </Button>
    </div>
    {props.buttonState === BUTTON_STATE.SUCCESS && <Leaderboard />}
    {props.responseMessage && <div>{props.responseMessage}</div>}
  </div>
);

export default connect(
  state => ({
    buttonState: state.db.leaderboard.buttonState,
    responseMessage: state.db.leaderboard.responseMessage,
  }),
  { getLeaderboard },
)(Events);
