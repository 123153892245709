import React from "react";
import { ControlLabel } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import EventCodeInput from "../helpers/event-code-input";

const EventForm = props => {
  return (
    <form>
      <ControlLabel>
        Event Code (All caps, no spaces, max 20 characters)
      </ControlLabel>
      <Field name="eventCode" component={EventCodeInput} />
      <ControlLabel>Event Name</ControlLabel>
      <Field name="eventName" className="form-control" component="input" />
    </form>
  );
};

const ReduxEventForm = reduxForm({
  form: "event",
  destroyOnUnmount: false,
})(EventForm);

const ConnectedEventForm = connect(null, { change })(ReduxEventForm);

export default ConnectedEventForm;
