export default [
  {
    name: "Abbuoto",
  },
  {
    name: "Abouriou",
  },
  {
    name: "Abrusco",
  },
  {
    name: "Acolon",
  },
  {
    name: "Ada Karasi",
  },
  {
    name: "Adalmiina",
  },
  {
    name: "Addoraca",
  },
  {
    name: "Affenthaler",
  },
  {
    name: "Agiorgitiko",
  },
  {
    name: "Aglianico",
  },
  {
    name: "Aglianicone",
  },
  {
    name: "Agni (grape) (cs)",
  },
  {
    name: "Agronomica",
  },
  {
    name: "Agua Santa",
  },
  {
    name: "Aidani / Aidini / Aedani",
  },
  {
    name: "Aidani Mavro",
  },
  {
    name: "Airén",
  },
  {
    name: "Akhasheni",
  },
  {
    name: "Aladasturi",
  },
  {
    name: "Alarije / Alarijen",
  },
  {
    name: "Albalonga",
  },
  {
    name: "Albana",
  },
  {
    name: "Albanella",
  },
  {
    name: "Albanello bianco",
  },
  {
    name: "Albaranzeuli bianco",
  },
  {
    name: "Albaranzeuli nero",
  },
  {
    name: "Albarello",
  },
  {
    name: "Albariño / Alvarinho / Cainho branco",
  },
  {
    name: "Albarola",
  },
  {
    name: "Albarossa",
  },
  {
    name: "Albillo",
  },
  {
    name: "Aleatico",
  },
  {
    name: "Alexander",
  },
  {
    name: "Alexandroouli",
  },
  {
    name: "Alfrocheiro",
  },
  {
    name: "Alicante Henri Bouschet",
  },
  {
    name: "Aligoté",
  },
  {
    name: "Alionza",
  },
  {
    name: "Allegro",
  },
  {
    name: "Altesse / Roussette",
  },
  {
    name: "Amaral",
  },
  {
    name: "Amigne",
  },
  {
    name: "Ancellotta",
  },
  {
    name: "André (grape) (cs)",
  },
  {
    name: "Ansonica / Inzolia",
  },
  {
    name: "Antao Vaz",
  },
  {
    name: "Antey Magarachsky",
  },
  {
    name: "Aragónez",
  },
  {
    name: "Aramon Noir",
  },
  {
    name: "Arany sárfehér / Izsáki",
  },
  {
    name: "Arbane",
  },
  {
    name: "Arbois",
  },
  {
    name: "Areni",
  },
  {
    name: "Argaman",
  },
  {
    name: "Argant",
  },
  {
    name: "Arilla",
  },
  {
    name: "Arinarnoa",
  },
  {
    name: "Arinto / Assario branco",
  },
  {
    name: "Arinto de Bucelas",
  },
  {
    name: "Arneis",
  },
  {
    name: "Arnsburger",
  },
  {
    name: "Arrouya",
  },
  {
    name: "Arrufiac / Arrufiat / Ruffiac",
  },
  {
    name: "Arvesiniadu",
  },
  {
    name: "Ashughaji",
  },
  {
    name: "Aspiran Bouschet",
  },
  {
    name: "Aspiran Noir",
  },
  {
    name: "Asprinio bianco",
  },
  {
    name: "Assario",
  },
  {
    name: "Assyrtiko / Assyrtico",
  },
  {
    name: "Asuretuli Shavi",
  },
  {
    name: "Athiri",
  },
  {
    name: "Aubin / Aubin blanc",
  },
  {
    name: "Aubin vert",
  },
  {
    name: "Aubun",
  },
  {
    name: "Aurelius",
  },
  {
    name: "Auxerrois blanc",
  },
  {
    name: "Avanà",
  },
  {
    name: "Avarengo",
  },
  {
    name: "Avesso",
  },
  {
    name: "Avgoustiatis",
  },
  {
    name: "Azal branco",
  },
  {
    name: "Băbească neagră",
  },
  {
    name: "Babić",
  },
  {
    name: "Bacchus",
  },
  {
    name: "Bachet Noir",
  },
  {
    name: "Baco blanc",
  },
  {
    name: "Baco Noir",
  },
  {
    name: "Baga",
  },
  {
    name: "Baiyu / Rkatsiteli",
  },
  {
    name: "Balzac blanc",
  },
  {
    name: "Banat Riesling / Banat Rizling",
  },
  {
    name: "Baratuciat",
  },
  {
    name: "Barbera",
  },
  {
    name: "Barbera bianca",
  },
  {
    name: "Barbera del Sannio",
  },
  {
    name: "Barbera Sarda",
  },
  {
    name: "Barcelo",
  },
  {
    name: "Bariadorgia",
  },
  {
    name: "Baroque",
  },
  {
    name: "Barsaglina",
  },
  {
    name: "Bastardo Magarachsky",
  },
  {
    name: "Beaunoir",
  },
  {
    name: "Béclan",
  },
  {
    name: "Beichun",
  },
  {
    name: "Bekari",
  },
  {
    name: "Belat",
  },
  {
    name: "Belina",
  },
  {
    name: "Benedino",
  },
  {
    name: "Béquignol Noir",
  },
  {
    name: "Besgano bianco",
  },
  {
    name: "Bia blanc",
  },
  {
    name: "Bianca",
  },
  {
    name: "Biancame / Bianchello",
  },
  {
    name: "Bianchetta Trevigiana",
  },
  {
    name: "Bianchetti Genovese",
  },
  {
    name: "Bianco d'Alessano",
  },
  {
    name: "Biancolella",
  },
  {
    name: "Biancone di Portoferraio",
  },
  {
    name: "Biancu Gentile",
  },
  {
    name: "Biborkadarka",
  },
  {
    name: "Bical / Borrado das Moscas",
  },
  {
    name: "Bigolona",
  },
  {
    name: "Black Queen",
  },
  {
    name: "Blatina",
  },
  {
    name: "Blatterle",
  },
  {
    name: "Blauburger",
  },
  {
    name: "Blauer Arbst (Pinot Noir)",
  },
  {
    name: "Blauer Wildbacher",
  },
  {
    name: "Blaufränkisch (Limberger)",
  },
  {
    name: "Boais",
  },
  {
    name: "Bobal",
  },
  {
    name: "Boğazkere",
  },
  {
    name: "Bogdanuša",
  },
  {
    name: "Bombino bianco",
  },
  {
    name: "Bombino Nero",
  },
  {
    name: "Bonamico",
  },
  {
    name: "Bonarda Piemontese",
  },
  {
    name: "Bonda",
  },
  {
    name: "Bondola",
  },
  {
    name: "Bondoletta",
  },
  {
    name: "Bonicaire",
  },
  {
    name: "Bonvedro",
  },
  {
    name: "Borba",
  },
  {
    name: "Borba blanca",
  },
  {
    name: "Borracal",
  },
  {
    name: "Bosco",
  },
  {
    name: "Bouchalès",
  },
  {
    name: "Bourboulenc",
  },
  {
    name: "Bourrisquou",
  },
  {
    name: "Bouteillan Noir",
  },
  {
    name: "Bouvier",
  },
  {
    name: "Bovale Grande",
  },
  {
    name: "Bovale Sardo",
  },
  {
    name: "Bracciola Nera",
  },
  {
    name: "Brachetto del Piemonte",
  },
  {
    name: "Brancellao",
  },
  {
    name: "Braquet Noir",
  },
  {
    name: "Bratislavské biele",
  },
  {
    name: "Breidecker",
  },
  {
    name: "Brugnola",
  },
  {
    name: "Brujidera",
  },
  {
    name: "Brun Argenté",
  },
  {
    name: "Brun Fourca",
  },
  {
    name: "Brunal",
  },
  {
    name: "Brunello",
  },
  {
    name: "Brustiano bianco",
  },
  {
    name: "Bual / Boal",
  },
  {
    name: "Bubbierasco",
  },
  {
    name: "Budai Zöld",
  },
  {
    name: "Buket",
  },
  {
    name: "Bukettraube",
  },
  {
    name: "Burger / Monbadon",
  },
  {
    name: "Busuioaca de Bohotin",
  },
  {
    name: "Caberinta",
  },
  {
    name: "Cabernet Carbon",
  },
  {
    name: "Cabernet Carol",
  },
  {
    name: "Cabernet Colonjes",
  },
  {
    name: "Cabernet Cortis",
  },
  {
    name: "Cabernet Cubin",
  },
  {
    name: "Cabernet Dorio",
  },
  {
    name: "Cabernet Dorsa",
  },
  {
    name: "Cabernet Franc",
  },
  {
    name: "Cabernet Gernischt",
  },
  {
    name: "Cabernet Jura",
  },
  {
    name: "Cabernet Mitos",
  },
  {
    name: "Cabernet Moravia (cs)",
  },
  {
    name: "Cabernet Pfeffer",
  },
  {
    name: "Cabernet Sauvignon",
  },
  {
    name: "Cabernet Severny",
  },
  {
    name: "Cabertin",
  },
  {
    name: "Caddiu",
  },
  {
    name: "Cagnulari",
  },
  {
    name: "Caíño blanco",
  },
  {
    name: "Caino Bravo",
  },
  {
    name: "Caiño Tinto",
  },
  {
    name: "Calabrese",
  },
  {
    name: "Calabrese di Montenuovo",
  },
  {
    name: "Caladoc",
  },
  {
    name: "Calandro",
  },
  {
    name: "Calitor Noir",
  },
  {
    name: "Çalkarası",
  },
  {
    name: "Callet",
  },
  {
    name: "Caloria",
  },
  {
    name: "Camaralet",
  },
  {
    name: "Camaraou Noir",
  },
  {
    name: "Camarate",
  },
  {
    name: "Camarese",
  },
  {
    name: "Campbell Early",
  },
  {
    name: "Canaiolo Nero",
  },
  {
    name: "Canari blanc",
  },
  {
    name: "Canari Noir",
  },
  {
    name: "Canina Nera",
  },
  {
    name: "Cannamela",
  },
  {
    name: "Caprettone",
  },
  {
    name: "Carcajolo Nero",
  },
  {
    name: "Carica l'Asino",
  },
  {
    name: "Caricagiola",
  },
  {
    name: "Carignan",
  },
  {
    name: "Carignan blanc",
  },
  {
    name: "Carinena",
  },
  {
    name: "Carménère",
  },
  {
    name: "Carmine",
  },
  {
    name: "Carminoir",
  },
  {
    name: "Carnelian",
  },
  {
    name: "Carrasquin",
  },
  {
    name: "Casavecchia",
  },
  {
    name: "Cascade",
  },
  {
    name: "Cascarolo bianco",
  },
  {
    name: "Casculho",
  },
  {
    name: "Casetta",
  },
  {
    name: "Castagnara",
  },
  {
    name: "Castelão",
  },
  {
    name: "Castets",
  },
  {
    name: "Castiglione",
  },
  {
    name: "Catalanesca",
  },
  {
    name: "Catanese Nero",
  },
  {
    name: "Catarratto",
  },
  {
    name: "Cavrara",
  },
  {
    name: "Cayetana / Calagraño / Jaén blanca / Garrido",
  },
  {
    name: "Centesimino",
  },
  {
    name: "Centurian",
  },
  {
    name: "Cereza",
  },
  {
    name: "Cesanese",
  },
  {
    name: "César",
  },
  {
    name: "Cevat Kara",
  },
  {
    name: "Chambourcin",
  },
  {
    name: "Chancellor",
  },
  {
    name: "Charbono",
  },
  {
    name: "Chardonnay",
  },
  {
    name: "Charentsi",
  },
  {
    name: "Chasan",
  },
  {
    name: "Chasselas / Fendant / Gutedel / Weisser Gutedel",
  },
  {
    name: "Chatus",
  },
  {
    name: "Chelois",
  },
  {
    name: "Chenanson",
  },
  {
    name: "Chenel",
  },
  {
    name: "Chenin blanc / Pineau de la Loire / Steen",
  },
  {
    name: "Chichaud",
  },
  {
    name: "Chidiriotiko",
  },
  {
    name: "Chisago",
  },
  {
    name: "Chkhaveri",
  },
  {
    name: "Chondromavro",
  },
  {
    name: "Cianorie",
  },
  {
    name: "Cienna",
  },
  {
    name: "Ciliegiolo",
  },
  {
    name: "Cinsaut",
  },
  {
    name: "Clairette",
  },
  {
    name: "Claret de Gers / Claret de Gascogne",
  },
  {
    name: "Claverie",
  },
  {
    name: "Cococciola",
  },
  {
    name: "Cocur",
  },
  {
    name: "Coda di Pecora",
  },
  {
    name: "Coda di Volpe / Guarnaccia bianca",
  },
  {
    name: "Colombana nera",
  },
  {
    name: "Colombard",
  },
  {
    name: "Colorino",
  },
  {
    name: "Completer",
  },
  {
    name: "Complexa",
  },
  {
    name: "Cornalin see Rouge du Pays",
  },
  {
    name: "Cornifesto",
  },
  {
    name: "Cortese",
  },
  {
    name: "Corvina / Corvinone",
  },
  {
    name: "Counoise",
  },
  {
    name: "Courbu / Xuri Zerratua / Bordelesa Zuri",
  },
  {
    name: "Crato / Crato bianco",
  },
  {
    name: "Criolla Grande",
  },
  {
    name: "Croatina",
  },
  {
    name: "Crouchen / Clare Riesling / Cape Riesling",
  },
  {
    name: "Cserszegi Fűszeres",
  },
  {
    name: "Cygne blanc",
  },
  {
    name: "Darnekuša",
  },
  {
    name: "Dattier",
  },
  {
    name: "Debina",
  },
  {
    name: "Debit",
  },
  {
    name: "Diagalves",
  },
  {
    name: "Dimiat",
  },
  {
    name: "Dinka",
  },
  {
    name: "Dobričić",
  },
  {
    name: "Dolcetto",
  },
  {
    name: "Domina",
  },
  {
    name: "Dona blanca",
  },
  {
    name: "Donzelinho branco",
  },
  {
    name: "Doradillo",
  },
  {
    name: "Dornfelder",
  },
  {
    name: "Douce noir/Charbono/Bonarda/Turca",
  },
  {
    name: "Douce Noire grise",
  },
  {
    name: "Drupeggio",
  },
  {
    name: "Dunkelfelder",
  },
  {
    name: "Duras",
  },
  {
    name: "Durella",
  },
  {
    name: "Dureza",
  },
  {
    name: "Durif / Petite Sirah",
  },
  {
    name: "Ederena",
  },
  {
    name: "Ehrenfelser",
  },
  {
    name: "Elbling",
  },
  {
    name: "Emerald Riesling",
  },
  {
    name: "Emir Karasi",
  },
  {
    name: "Encruzado",
  },
  {
    name: "Enfariné noir",
  },
  {
    name: "Erbaluce",
  },
  {
    name: "Espadeiro",
  },
  {
    name: "Esquitxagos",
  },
  {
    name: "Étraire",
  },
  {
    name: "Ezerjó",
  },
  {
    name: "Faber / Faberrebe",
  },
  {
    name: "Falanghina",
  },
  {
    name: "False Pedro / Pedro Luis / Cañocazo",
  },
  {
    name: "Farana",
  },
  {
    name: "Favorita",
  },
  {
    name: "Fer",
  },
  {
    name: "Fernao Pires / Fernão Pires",
  },
  {
    name: "Ferral",
  },
  {
    name: "Ferrón",
  },
  {
    name: "Fetească albă / Fetiaska / Leànyka",
  },
  {
    name: "Fetească neagră",
  },
  {
    name: "Fetească regală",
  },
  {
    name: "Fiano",
  },
  {
    name: "Fié / Fiét / Fié gris",
  },
  {
    name: "Findling",
  },
  {
    name: "Flora",
  },
  {
    name: "Folle blanche / Gros Plant / Piquepoult",
  },
  {
    name: "Fologosão",
  },
  {
    name: "Forastera",
  },
  {
    name: "Forastera (Spanish grape)",
  },
  {
    name: "Forcallat tinta",
  },
  {
    name: "Fortana",
  },
  {
    name: "Francavida",
  },
  {
    name: "Francusa",
  },
  {
    name: "Frappato",
  },
  {
    name: "Freisa",
  },
  {
    name: "Freisamer / Freiburger",
  },
  {
    name: "Fromenteau",
  },
  {
    name: "Frühroter Veltliner",
  },
  {
    name: "Fumin",
  },
  {
    name: "Furmint / Mosler / Sipon",
  },
  {
    name: "Gaglioppo",
  },
  {
    name: "Galego Dourado",
  },
  {
    name: "Gamaret",
  },
  {
    name: "Gamashara",
  },
  {
    name: "Gamay / Gamay noir",
  },
  {
    name: "Garanoir",
  },
  {
    name: "Garganega / Grecanico / Grecanio",
  },
  {
    name: "Garnacha blanca / Grenache blanc",
  },
  {
    name:
      "Garnatxa / Grenache / Garnacha / Cannonau /Lladoner Pelut / Lledoner Pelut",
  },
  {
    name: "Gellewza",
  },
  {
    name: "Gewürztraminer / Tramini / Traminac",
  },
  {
    name: "Girò",
  },
  {
    name: "Giró blanc",
  },
  {
    name: "Gloria",
  },
  {
    name: "Godello",
  },
  {
    name: "Goldburger",
  },
  {
    name: "Goldriesling",
  },
  {
    name: "Gouais blanc",
  },
  {
    name: "Gouget noir",
  },
  {
    name: "Graciano/ Moristel/ Juan Ibáñez / Miguel del Arco",
  },
  {
    name: "Graisse / Plant de Graisse",
  },
  {
    name: "Grand noir de la Calmette",
  },
  {
    name: "Grasa / Grasa de Cotnari",
  },
  {
    name: "Grasă de Cotnari",
  },
  {
    name: "Grechetto",
  },
  {
    name: "Greco bianco",
  },
  {
    name: "Greco nero",
  },
  {
    name: "Green Hungarian",
  },
  {
    name: "Grenache blanc / Garnacha blanca",
  },
  {
    name: "Grenache gris",
  },
  {
    name: "Grignolino",
  },
  {
    name: "Grillo / Riddu / Rossese bianco",
  },
  {
    name: "Grisa nera",
  },
  {
    name: "Grk Bijeli",
  },
  {
    name: "Grolleau / Groslot",
  },
  {
    name: "Grolleau gris",
  },
  {
    name: "Gropello",
  },
  {
    name: "Gros Manseng / Izkiriota Handi",
  },
  {
    name: "Gros Verdot",
  },
  {
    name: "Grüner Veltliner",
  },
  {
    name: "Guardavalle",
  },
  {
    name: "Gueuche noir",
  },
  {
    name: "Gutenborner",
  },
  {
    name: "Hárslevelű",
  },
  {
    name: "Helfensteiner",
  },
  {
    name: "Heroldrebe",
  },
  {
    name: "Hondarrabi Zuri",
  },
  {
    name: "Hondarribi Beltza",
  },
  {
    name: "Hron",
  },
  {
    name: "Humagne Blanche",
  },
  {
    name: "Humagne Rouge / Cornalin d'Aoste",
  },
  {
    name: "Huxelrebe / Courtillier Musqué",
  },
  {
    name: "Incrocio Manzoni",
  },
  {
    name: "Irsai Oliver / Irsay Oliver / Irsai Olivér",
  },
  {
    name: "Italia",
  },
  {
    name: "Jacquère",
  },
  {
    name: "Jampal",
  },
  {
    name: "Joubertin / Jaubertin",
  },
  {
    name: "Juan Garcia",
  },
  {
    name: "Juhfark",
  },
  {
    name: "Jurancon noir",
  },
  {
    name: "Juwel",
  },
  {
    name: "Kadarka",
  },
  {
    name: "Kakhet",
  },
  {
    name: "Kalecik Karasi",
  },
  {
    name: "Kanzler",
  },
  {
    name: "Karija l'Osü",
  },
  {
    name: "Keknyelu / Kéknyelű",
  },
  {
    name: "Kerner",
  },
  {
    name: "Kindzmarauli/ Saperavi",
  },
  {
    name: "Knipperle / Klein Rauschling",
  },
  {
    name: "Koshu",
  },
  {
    name: "Kotsifali",
  },
  {
    name: "Kövérszőlő",
  },
  {
    name: "Krasnostop Zolotovsky",
  },
  {
    name: "Kratosija",
  },
  {
    name: "Krstač",
  },
  {
    name: "Kujundžuša",
  },
  {
    name: "Lacrima di Morro / Lacrima nera",
  },
  {
    name: "Ladikino",
  },
  {
    name: "Lado",
  },
  {
    name: "Lagarino bianco",
  },
  {
    name: "Lagorthi",
  },
  {
    name: "Lagrein",
  },
  {
    name: "Lambrusco",
  },
  {
    name: "Lauzet",
  },
  {
    name: "Len de l'El / Len de l'Elh / Loin-de-l'oeil",
  },
  {
    name: "Liatiko",
  },
  {
    name: "Limnio",
  },
  {
    name: "Listan negro",
  },
  {
    name: "Loureira",
  },
  {
    name: "Luglienga",
  },
  {
    name: "Lumassina",
  },
  {
    name: "Macabeo / Macabeu / Alcañón / Viura",
  },
  {
    name: "Maceratino",
  },
  {
    name: "Madeleine Angevine",
  },
  {
    name: "Madrasa/ Matrassa",
  },
  {
    name: "Magarach Bastardo/Bastardo Magarach",
  },
  {
    name: "Magaratch Ruby / Magarach Ruby",
  },
  {
    name: "Magliocco Canino/Maiolica",
  },
  {
    name: "Magliocco Dolce/Marsigliana nera",
  },
  {
    name: "Magyarfrankos",
  },
  {
    name: "Majarcă Albă",
  },
  {
    name: "Malagousia / Malagoussia",
  },
  {
    name: "Malbec / Auxerrois / Cot",
  },
  {
    name: "Malvar",
  },
  {
    name: "Malvasia di Schierano/Malvasia nera",
  },
  {
    name: "Malvasia, includes several sub-varieties",
  },
  {
    name: "Mammolo",
  },
  {
    name: "Mandelaria / Mandelari / Amorghiano",
  },
  {
    name: "Mandolari",
  },
  {
    name: "Manseng noir",
  },
  {
    name: "Manteudo",
  },
  {
    name: "Manto negro",
  },
  {
    name: "Mantonico bianco",
  },
  {
    name: "Mara",
  },
  {
    name: "Maratheftiko",
  },
  {
    name: "Maria Gomes / Fernão Pires",
  },
  {
    name: "Marsanne",
  },
  {
    name: "Marselan",
  },
  {
    name: "Marsigliana",
  },
  {
    name: "Marzemina bianca",
  },
  {
    name: "Marzemino",
  },
  {
    name: "Mátrai Muskotály",
  },
  {
    name: "Mauzac",
  },
  {
    name: "Mauzac noir",
  },
  {
    name: "Mavro",
  },
  {
    name: "Mavrodafni/ Mavrodaphne",
  },
  {
    name: "Mavrud / Mavroudi",
  },
  {
    name: "Mayorquin",
  },
  {
    name: "Meghrabujr",
  },
  {
    name: "Melnik",
  },
  {
    name: "Melon de Bourgogne / Muscadet",
  },
  {
    name: "Mencia/ Jaen",
  },
  {
    name: "Merenzao",
  },
  {
    name: "Merille",
  },
  {
    name: "Merlot",
  },
  {
    name: "Merlot blanc",
  },
  {
    name: "Merseguera / Escanyavella / Verdil / Verdosilla",
  },
  {
    name: "Meslier St-François",
  },
  {
    name: "Mezesfehér",
  },
  {
    name: "Milgranet",
  },
  {
    name: "Millot /Léon Millot",
  },
  {
    name: "Minella bianca",
  },
  {
    name: "Miousap",
  },
  {
    name: "Mission",
  },
  {
    name: "Molette",
  },
  {
    name: "Molinara",
  },
  {
    name: "Moll",
  },
  {
    name: "Mondeuse",
  },
  {
    name: "Monica",
  },
  {
    name: "Montepulciano",
  },
  {
    name: "Montonico bianco",
  },
  {
    name: "Montu",
  },
  {
    name: "Montù / Montuni",
  },
  {
    name: "Moreto",
  },
  {
    name: "Morio-Muskat",
  },
  {
    name: "Moristel",
  },
  {
    name: "Mornen noir",
  },
  {
    name: "Morrastel Bouschet",
  },
  {
    name: "Moscatel Rosada",
  },
  {
    name: "Moscato Giallo",
  },
  {
    name: "Moschofilero / Moscophilero",
  },
  {
    name: "Mourisco tinto",
  },
  {
    name: "Mourvèdre / Monastrell / Mataro/ Rossola nera/Garrut",
  },
  {
    name: "Mouyssaguès",
  },
  {
    name: "Mtevandidi",
  },
  {
    name: "Mtsvane",
  },
  {
    name: "Mujuretuli",
  },
  {
    name: "Müller-Thurgau / Rivaner",
  },
  {
    name: "Muscadelle (Tokay in Australia)",
  },
  {
    name: "Muscardin",
  },
  {
    name: "Muscat / Moscato / Misket",
  },
  {
    name:
      "Muscat Blanc à Petits Grains / Muscat Frontignan / Muskateller / Moscatel \nbranco / Frontignan",
  },
  {
    name: "Muscat Bouschet",
  },
  {
    name: "Muscat Hamburg",
  },
  {
    name: "Muscat of Alexandria / Moscatell / Moscatel de Málaga, de Setúbal",
  },
  {
    name: "Muscat Ottonel",
  },
  {
    name: "Nasco",
  },
  {
    name: "Nebbiolo",
  },
  {
    name: "Negoska",
  },
  {
    name: "Negrara",
  },
  {
    name: "Négrette/ Pinot St. George",
  },
  {
    name: "Negroamaro",
  },
  {
    name: "Negru de Dragasani",
  },
  {
    name: "Neherleschol",
  },
  {
    name: "Nerello Mascalese/ Nerello Cappuccio",
  },
  {
    name: "Neretto di Bairo",
  },
  {
    name: "Nerkarat",
  },
  {
    name: "Nero Buono di Cori",
  },
  {
    name: "Nero d'Avola",
  },
  {
    name: "Neuburger",
  },
  {
    name: "Neyret",
  },
  {
    name: "Nielluccio",
  },
  {
    name: "Ninčuša",
  },
  {
    name: "Nobling",
  },
  {
    name: "Nocera",
  },
  {
    name: "Nosiola",
  },
  {
    name: "Notardomenico",
  },
  {
    name: "Nuragus",
  },
  {
    name: "Oeillade noire",
  },
  {
    name: "Ojaleshi",
  },
  {
    name: "Öküzgözü",
  },
  {
    name: "Ondenc",
  },
  {
    name: "Optima",
  },
  {
    name: "Oraniensteiner",
  },
  {
    name: "Orion",
  },
  {
    name: "Ortega",
  },
  {
    name: "Ortrugo",
  },
  {
    name: "Oseleta",
  },
  {
    name: "Oz",
  },
  {
    name: "Pagedebit",
  },
  {
    name: "Pais",
  },
  {
    name: "Pallagrello bianco",
  },
  {
    name: "Pallagrello nero",
  },
  {
    name: "Palomino / Listan / Perrum",
  },
  {
    name: "Pamid",
  },
  {
    name: "Pampanuto / Pampanino",
  },
  {
    name: "Parč",
  },
  {
    name: "Pardillo / Pardina",
  },
  {
    name: "Parellada",
  },
  {
    name: "Pascal blanc",
  },
  {
    name: "Pascale di Cagliari",
  },
  {
    name: "Passerina",
  },
  {
    name: "Pearl of Csaba",
  },
  {
    name: "Pecorino / Pecorello",
  },
  {
    name: "Pedro Giménez",
  },
  {
    name: "Pedro Ximénez/ PX / Alamís",
  },
  {
    name: "Pelaverga",
  },
  {
    name: "Peloursin",
  },
  {
    name: "Perdal",
  },
  {
    name: "Perle",
  },
  {
    name: "Perricone/Guarnaccia",
  },
  {
    name: "Persan",
  },
  {
    name: "Petit Bouschet",
  },
  {
    name: "Petit Courbu",
  },
  {
    name: "Petit Manseng / Izkiriota Ttipi",
  },
  {
    name: "Petit Meslier",
  },
  {
    name: "Petit Rouge",
  },
  {
    name: "Petit Verdot",
  },
  {
    name: "Petite Arvine",
  },
  {
    name: "Picardin / Picardan / Aragnan blanc",
  },
  {
    name: "Piccola nera",
  },
  {
    name: "Picolit / Piccolit / Piccolito",
  },
  {
    name: "Picpoul / Piquepoul blanc / Piquepoul gris",
  },
  {
    name: "Piedirosso",
  },
  {
    name: "Pigato",
  },
  {
    name: "Pignerol",
  },
  {
    name: "Pignola Valtellinese",
  },
  {
    name: "Pignoletto",
  },
  {
    name: "Pignolo",
  },
  {
    name: "Pineau d'Aunis",
  },
  {
    name: "Pinella",
  },
  {
    name: "Pinot blanc / Pinot bianco / Klevner / Weissburgunder",
  },
  {
    name:
      "Pinot gris / Pinot grigio / Grauburgunder / Malvoisie / Pinot jaune / \nSzürkebarát",
  },
  {
    name: "Pinot Meunier / Schwarzriesling / Müllerebe",
  },
  {
    name: "Pinot noir / Spätburgunder / Blauburgunder / Pinot nero",
  },
  {
    name: "Pinotage",
  },
  {
    name: "Piquepoul noir",
  },
  {
    name: "Planta Fina",
  },
  {
    name: "Planta Nova",
  },
  {
    name: "Plassa",
  },
  {
    name: "Plavai / Plavay",
  },
  {
    name:
      "Plavina / Brajda Mala / Brajdica / Bratkovina / Jurkovica / Marasovka \n/Modrulj / Plavac Plavina /Plavinac /Plavina Mala / Plavina Sitnah / \nPlavina Velka / Plavka / Plavka Mala / Velika Plavka",
  },
  {
    name: "Pollera nera",
  },
  {
    name: "Portan",
  },
  {
    name: "Pošip",
  },
  {
    name: "Poulsard/ Plousard",
  },
  {
    name: "Prié blanc / Blanc de Morgex",
  },
  {
    name: "Prieto Picudo",
  },
  {
    name: "Prokupac",
  },
  {
    name: "Prosecco / Glera",
  },
  {
    name: "Prunesta",
  },
  {
    name: "Rabigato",
  },
  {
    name: "Rabo de Ovelha",
  },
  {
    name: "Raboso",
  },
  {
    name: "Raisin blanc",
  },
  {
    name: "Ramisco",
  },
  {
    name: "Rauschling / Rāuschling",
  },
  {
    name: "Reberger",
  },
  {
    name: "Refosco / Refošk",
  },
  {
    name: "Regner",
  },
  {
    name: "Reichensteiner",
  },
  {
    name: "Retagliado bianco",
  },
  {
    name: "Rèze",
  },
  {
    name: "Ribolla Gialla / Robola",
  },
  {
    name: "Rieslaner",
  },
  {
    name: "Riesling / Johannisberg Riesling / Rheinriesling / Klingelberger",
  },
  {
    name: "Rimava",
  },
  {
    name: "Rkatsiteli",
  },
  {
    name: "Robola",
  },
  {
    name: "Roditis / Rhoditis",
  },
  {
    name: "Roesler",
  },
  {
    name: "Rollo",
  },
  {
    name: "Romorantin",
  },
  {
    name: "Rondinella",
  },
  {
    name: "Rossese",
  },
  {
    name: "Rossese bianco",
  },
  {
    name: "Rossignola",
  },
  {
    name: "Rossolino nero",
  },
  {
    name: "Rotberger",
  },
  {
    name: "Roter Veltliner",
  },
  {
    name: "Rotgipfler",
  },
  {
    name: "Rouchet/ Ruché / Roche",
  },
  {
    name: "Roupeiro / Codega",
  },
  {
    name: "Roussanne",
  },
  {
    name: "Rovello bianco",
  },
  {
    name: "Rubintos",
  },
  {
    name: "Ruby Cabernet",
  },
  {
    name: "Rufete / Tinta Pinheira / Tinta Carvalha / Rufeta",
  },
  {
    name: "Sabro",
  },
  {
    name: "Sacy / Tresallier",
  },
  {
    name: "Sagrantino",
  },
  {
    name: "Saint-Macaire",
  },
  {
    name: "Saint-Pierre Doré",
  },
  {
    name: "San Giuseppe nero",
  },
  {
    name: "Sangiovese",
  },
  {
    name: "Saperavi",
  },
  {
    name: "Sarfeher / Sárfehér",
  },
  {
    name: "Sauvignon Blanc",
  },
  {
    name: "Sauvignon vert / Sauvignonasse / Friulano / Tocai Friulano",
  },
  {
    name: "Savagnin / Savagnin blanc / Traminer",
  },
  {
    name: "Savatiano",
  },
  {
    name: "Scheurebe",
  },
  {
    name: "Schiava / Trollinger",
  },
  {
    name: "Schioppettino",
  },
  {
    name: "Schönburger",
  },
  {
    name: "Sciacarello",
  },
  {
    name: "Sciascinoso / Olivella nera",
  },
  {
    name: "Segalin / Ségalin",
  },
  {
    name: "Sémillon",
  },
  {
    name: "Septiner",
  },
  {
    name: "Sercial / Cerceal / Esgana Cão / Esgana",
  },
  {
    name: "Sereksia / Băbească albă",
  },
  {
    name: "Servanin",
  },
  {
    name: "Sgaretta/Sgavetta",
  },
  {
    name: "Shiraz / Syrah",
  },
  {
    name: "Shiroka Melnishka Losa / Melnik",
  },
  {
    name: "Sideritis",
  },
  {
    name: "Siegerrebe",
  },
  {
    name: "Silvaner / Sylvaner / Österreicher",
  },
  {
    name: "Smederevka",
  },
  {
    name: "Sousão/Souzão/Sousón",
  },
  {
    name: "Souvignier gris",
  },
  {
    name: "Spagnol",
  },
  {
    name: "St. Laurent / Svatovavrinecke",
  },
  {
    name: "Ste Marie",
  },
  {
    name: "Sultana",
  },
  {
    name: "Sumoll",
  },
  {
    name: "Susumaniello",
  },
  {
    name: "Symphony",
  },
  {
    name: "Tamarêz / Tamares / Crato branco",
  },
  {
    name: "Tamîioasa / Tămâioasă Românească / Tamianka",
  },
  {
    name: "Taminga",
  },
  {
    name: "Tannat",
  },
  {
    name: "Tarrango",
  },
  {
    name: "Tazzelenghe",
  },
  {
    name:
      "Tempranillo / Tinta Roriz / Ull de Llebre / Cencibel / Tinta del Pais",
  },
  {
    name: "Tempranillo blanco",
  },
  {
    name: "Téoulier",
  },
  {
    name: "Termarina rossa",
  },
  {
    name: "Teroldego / Teroldego Rotaliano",
  },
  {
    name: "Terrantez",
  },
  {
    name: "Terret gris",
  },
  {
    name: "Terret noir",
  },
  {
    name: "Thiniatiko",
  },
  {
    name: "Tibouren",
  },
  {
    name: "Timorasso",
  },
  {
    name: "Tinta Amarela",
  },
  {
    name: "Tinta Barroca",
  },
  {
    name: "Tinta Caiada",
  },
  {
    name: "Tinta Carvalha",
  },
  {
    name: "Tinta Francisca",
  },
  {
    name: "Tinta Madeira",
  },
  {
    name: "Tinta Miuda",
  },
  {
    name: "Tinta Negra Mole / Preto Martinho",
  },
  {
    name: "Tinto Cão",
  },
  {
    name: "Torrontés / Torontel / Moscatel de Austria",
  },
  {
    name: "Tourbat / Torbato",
  },
  {
    name: "Touriga Franca / Touriga Francesa",
  },
  {
    name: "Touriga Nacional / Azal Espanhol / Preto de Mortágua",
  },
  {
    name: "Trbljan",
  },
  {
    name: "Trebbiano / Ugni blanc",
  },
  {
    name: "Treixadura / Trajadura",
  },
  {
    name: "Trepat",
  },
  {
    name: "Trevisana nera",
  },
  {
    name: "Trincadeira/ Castelão / Torneiro",
  },
  {
    name: "Trousseau gris / Grey Riesling",
  },
  {
    name: "Tsaoussi",
  },
  {
    name: "Tsolikauri",
  },
  {
    name: "Turán",
  },
  {
    name: "Usakhelauri",
  },
  {
    name:
      "Uva di Troia /Nero di Troia/ Sumarello/ Uva di Canosa/ Uva di Barletta/ \nTroiano/ Tranese / Uva della Marina.",
  },
  {
    name: "Uva Rara",
  },
  {
    name: "Uva Tosca",
  },
  {
    name: "Uvalino",
  },
  {
    name: "Vaccarese / Vaccarèse/ Brun Argenté",
  },
  {
    name: "Valdiguié / Brocol / Napa Gamay / Gamay 15",
  },
  {
    name: "Valentino nero",
  },
  {
    name: "Vega",
  },
  {
    name: "Verdea",
  },
  {
    name: "Verdeca",
  },
  {
    name: "Verdejo",
  },
  {
    name: "Verdelho / Gouveio / Verdello",
  },
  {
    name: "Verdello",
  },
  {
    name: "Verdesse",
  },
  {
    name: "Verdicchio",
  },
  {
    name: "Verdiso / Verdia",
  },
  {
    name: "Verdoncho",
  },
  {
    name: "Verduzzo",
  },
  {
    name: "Verduzzo Trevigiano",
  },
  {
    name: "Vermentino / Rolle",
  },
  {
    name: "Vermentino nero",
  },
  {
    name: "Vernaccia",
  },
  {
    name: "Vernaccia di Oristano",
  },
  {
    name: "Veroga",
  },
  {
    name: "Versoaln",
  },
  {
    name: "Vespaiola",
  },
  {
    name: "Vespolina",
  },
  {
    name: "Vien de Nus",
  },
  {
    name: "Vilana",
  },
  {
    name: "Vinhao",
  },
  {
    name: "Viognier",
  },
  {
    name: "Viosinho",
  },
  {
    name: "Vital (grape)",
  },
  {
    name: "Vitovska",
  },
  {
    name: "Volidza/Volitsa",
  },
  {
    name: "Voskehat",
  },
  {
    name: "Vranac",
  },
  {
    name: "Vugava",
  },
  {
    name: "Vuillermin",
  },
  {
    name: "Weldra",
  },
  {
    name:
      "Welschriesling / Riesling Italico / Olaszrizling / Lazki Rizling / Graševina",
  },
  {
    name: "Wildbacher/Blauer Wildbacher",
  },
  {
    name: "Wrothham Pinot",
  },
  {
    name: "Würzer",
  },
  {
    name: "Xarel·lo / Xarello",
  },
  {
    name: "Xinomavro",
  },
  {
    name: "Xynisteri",
  },
  {
    name: "Zala Gyöngye",
  },
  {
    name: "Zalema",
  },
  {
    name: "Žametovka",
  },
  {
    name: "Zefir",
  },
  {
    name: "Zengő",
  },
  {
    name: "Zenit",
  },
  {
    name: "Zéta / Orémus",
  },
  {
    name: "Zeusz",
  },
  {
    name: "Zierfandler / Spätrot",
  },
  {
    name: "Žilavka",
  },
  {
    name: "Zinfandel / Crljenak Kaštelanski / Primitivo",
  },
  {
    name: "Žlahtina",
  },
  {
    name: "Zweigelt / Zweigeltrebe / Rotburger",
  },
];
