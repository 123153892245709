import { getDynamoDb } from "../selectors";
import { LEADERBOARD_TABLE_NAME } from "../../../helpers/constants";
import { get } from "lodash";
import { sumOfPoints } from "../../../helpers/calculate-points";

export const createLeaderboardItem = state => {
  const points = sumOfPoints({
    numberOfWines: state.display.numberOfWines,
    conclusions: state.form.conclusions,
    results: state.form.results,
  });

  const Item = {
    EventCode: {
      S: get(state, "form.general.values.eventCode"),
    },
    LeaderboardName: {
      S: get(state, "form.leaderboardName.values.name"),
    },
    Points: {
      S: JSON.stringify(points),
    },
  };
  return {
    Item,
    TableName: LEADERBOARD_TABLE_NAME,
  };
};

const getLeaderboardLoading = () => {
  return {
    type: "GET_LEADERBOARD_LOADING",
  };
};

const getLeaderboardComplete = (err, data) => {
  return {
    type: "GET_LEADERBOARD_COMPLETE",
    payload: {
      err,
      data,
    },
  };
};

export const getLeaderboard = () => {
  return async (dispatch, getState) => {
    dispatch(getLeaderboardLoading());

    const dynamoDb = await getDynamoDb(dispatch, getState);

    var params = {
      ExpressionAttributeValues: {
        ":v1": {
          S: getState().form.leaderboardCode.values.code,
        },
      },
      KeyConditionExpression: "EventCode = :v1",
      TableName: LEADERBOARD_TABLE_NAME,
    };

    dynamoDb.query(params, function(err, data) {
      dispatch(getLeaderboardComplete(err, data));
    });
  };
};
