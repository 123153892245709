import { round, sum, times } from "lodash";
import wines from "../helpers/wine-names";
import { get, flatMapDeep, uniq } from "lodash";
import { MAX_NUMBER_OF_WINES } from "../helpers/constants";
import rwc from "random-weighted-choice";

export const shouldShowAdvancedFields = state =>
  state.form.advancedFieldsCheckbox &&
  state.form.advancedFieldsCheckbox.values &&
  state.form.advancedFieldsCheckbox.values.showAdvanced;

export const isEventMode = state =>
  !!get(state, "form.general.values.eventCode");

export const shouldPostToLeaderboard = state =>
  isEventMode(state) && !!get(state, "form.leaderboardName.values.name");

// TODO ideally need to start using redux for this and connect wine-stats to redux
export const getWineStats = (historyData, key) =>
  historyData.reduce((stats, row) => {
    times(MAX_NUMBER_OF_WINES, index => {
      const rKey = `results_Wine${index}_${key}`;
      const cKey = `conclusions_Wine${index}_${key}`;
      if (row[rKey]) {
        const wineKey = row[rKey].S;

        const rightOrWrong =
          wineKey &&
          row[cKey] &&
          row[cKey].S &&
          wineKey.toLowerCase() === row[cKey].S.toLowerCase()
            ? "right"
            : "wrong";

        if (!stats[wineKey]) {
          stats[wineKey] = {};
        }
        stats[wineKey][rightOrWrong] = stats[wineKey][rightOrWrong]
          ? stats[wineKey][rightOrWrong] + 1
          : 1;
      }
    });
    return stats;
  }, {});

const keyExists = (row, key, index) => {
  const rKey = `results_Wine${index}_${key}`;
  return !!row[rKey];
};

const gotKeyRight = (row, key, index, transform) => {
  const rKey = `results_Wine${index}_${key}`;
  const cKey = `conclusions_Wine${index}_${key}`;
  if (row[rKey]) {
    const resultsKey = row[rKey].S && row[rKey].S.toLowerCase();
    const conclusionsKey =
      row[cKey] && row[cKey].S && row[cKey].S.toLowerCase();

    if (transform) {
      return transform(resultsKey) === transform(conclusionsKey);
    }

    return resultsKey === conclusionsKey;
  }
};

export const getPercentageRight = (historyData, keysToGetRight, transform) => {
  const finalStats = historyData.reduce((stats, row) => {
    times(MAX_NUMBER_OF_WINES, index => {
      const anyKeysExist = keysToGetRight.some(key =>
        keyExists(row, key, index),
      );
      if (anyKeysExist) {
        const gotAnyKeysRight = keysToGetRight.some(key =>
          gotKeyRight(row, key, index, transform),
        );
        stats.push(gotAnyKeysRight);
      }
    });
    return stats;
  }, []);
  return round(sum(finalStats, val => !!val) / finalStats.length * 100, 2);
};

export const getRandomNeedsImprovementWine = (data, dontHaveWines) => {
  const stats = getWineStats(data, "varietals");
  const sortKeysByWrong = Object.keys(stats).sort((a, b) => {
    const aTotal = stats[a].right + stats[a].wrong;
    const bTotal = stats[b].right + stats[b].wrong;

    if (aTotal < 10 || bTotal < 10) {
      return aTotal - bTotal;
    }

    return stats[a].right / aTotal - stats[b].right / bTotal;
  });

  const filteredKeys = sortKeysByWrong.filter(name =>
    wines.some(wine => wine.name === name),
  );

  const untriedWines = wines
    .map(wine => wine.name)
    .filter(name => !filteredKeys.includes(name));

  const untriedThenFiltered = untriedWines.concat(filteredKeys); //put wines that haven't been tried at all before wines that you've gotten wrong

  const noDontHaveWines = untriedThenFiltered.filter(
    wineName => !dontHaveWines.includes(wineName),
  );
  const weightedTable = noDontHaveWines.map((value, index) => ({
    weight: noDontHaveWines.length - index,
    id: value,
  }));

  const selectedVarietalName = rwc(weightedTable);

  return wines.find(wine => wine.name === selectedVarietalName);
};

export const allTestableCountryNames = uniq(
  flatMapDeep(wines.map(wine => (wine.countries ? wine.countries : []))).map(
    country => country.name,
  ),
).sort();
