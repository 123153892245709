import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { resetFormState } from "../../modules/form/actions";
import { eventSaveReset } from "../../modules/db/event/actions";
import { get } from "lodash";

const SaveSuccessModal = props => {
  const resetAndPush = () => {
    props.eventSaveReset();
    props.resetFormState();
    props.push("/entry");
  };

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Event saved!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Now just tell people to go to theblindflight.com and enter code:{" "}
          <strong>{props.eventCode}</strong> after they guess.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="success"
          style={{ width: "inherit" }}
          onClick={resetAndPush}
        >
          OK - Let's start tasting!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(
  state => ({ eventCode: get(state, "form.event.values.eventCode") }),
  {
    push,
    resetFormState,
    eventSaveReset,
  },
)(SaveSuccessModal);
