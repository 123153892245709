import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import formReducer from "./form/reducer";
import authReducer from "./auth/reducer";
import dbReducer from "./db/reducer";
import displayReducer from "./display/reducer";
import blindPurchaseReducer from "./blind-purchase/reducer";

export default combineReducers({
  router: routerReducer,
  form: formReducer,
  auth: authReducer,
  db: dbReducer,
  display: displayReducer,
  blindPurchase: blindPurchaseReducer,
});
