import { reducer as formReducer } from "redux-form";
import reduceReducers from "reduce-reducers";

const stateRestoreReducer = (state, action) => {
  switch (action.type) {
    case "RESTORE_FORM_STATE":
      return action.payload.formState;
    case "RESET_FORM_STATE":
      return {};
    case "EVENT_FETCH_COMPLETE":
      return action.payload.results
        ? {
            ...state,
            results: action.payload.results,
          }
        : state;
    default:
      return state;
  }
};
const reducer = reduceReducers(stateRestoreReducer, formReducer);

export default reducer;
