import React from "react";
import { Field } from "redux-form";
import { ControlLabel } from "react-bootstrap";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import { camelCase } from "lodash";

import {
  toggleTastingGrid,
  toggleWinePredictions,
} from "../../modules/display/actions";
import { getWinePredictions } from "../../modules/tasting-grid.selectors";

const clarity = ["Clear", "Cloudy", "Opaque"];
const brightness = [
  "Cloudy",
  "Hazy",
  "Dull",
  "Bright",
  "Day Bright",
  "Star Bright",
  "Brilliant",
];
const color = [
  "Watery",
  "Straw",
  "Yellow",
  "Gold",
  "Pink",
  "Salmon",
  "Purple",
  "Ruby (Red)",
  "Garnet (Reddish-Brown)",
  "Brown",
];
const lmh = () => ["Low", "Medium-Minus", "Medium", "Medium-Plus", "High"];
const aroma = [
  "Black Fruit",
  "Dried Fruit",
  "Red Fruit",
  "Tropical Fruit",
  "Tree Fruit",
  "Citrus Fruit",
  "Floral and Herbal",
  "Earth",
];
const oak = ["Yes", "No"];
const sweetness = ["Dry", "Off-Dry", "Sweet", "Very Sweet"];
const finish = ["Short", "Medium-Minus", "Medium", "Medium-Plus", "Long"];

/**
 * @typedef {Object} TastingGridProps
 * @property {boolean} openTastingGrid
 * @property {boolean} openWinePredictions
 * @property {any} conclusionsData
 * @property {WinePrediction[]} winePredictions
 * @property {string} form
 * @property {(form: string) => any} toggleWinePredictions
 * @property {(form: string) => any} toggleTastingGrid
 */

/**
 *
 * @param {TastingGridProps} props
 */
const TastingGrid = props => {
  const renderSelectField = (name, options) => {
    if (props.conclusionsData && !props.conclusionsData[name]) {
      return null;
    }

    return (
      <div>
        <ControlLabel>{name}</ControlLabel>
        {props.conclusionsData ? (
          <div>{props.conclusionsData[name]}</div>
        ) : (
          <Field
            name={camelCase(name)}
            component="select"
            className="form-control"
          >
            <option key="" value="" />
            {options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Field>
        )}
      </div>
    );
  };

  return (
    <div>
      <Button onClick={() => props.toggleTastingGrid(props.form)}>
        Toggle Tasting Grid
      </Button>
      <Collapse in={props.openTastingGrid}>
        <div>
          {renderSelectField("Clarity", clarity)}
          {renderSelectField("Brightness", brightness)}
          {renderSelectField("Color", color)}
          {renderSelectField("Viscosity", lmh())}
          {!props.conclusionsData && <hr />}
          {renderSelectField("Aroma Intensity", lmh())}
          {renderSelectField("Aroma", aroma)}
          {renderSelectField("Oak", oak)}
          {!props.conclusionsData && <hr />}
          {renderSelectField("Sweetness", sweetness)}
          {renderSelectField("Body", lmh())}
          {renderSelectField("Acidity", lmh())}
          {renderSelectField("Alcohol", lmh())}
          {renderSelectField("Tannin", lmh())}
          {renderSelectField("Finish", finish)}
          {renderSelectField("Complexity", lmh())}
          <hr />
          <Button onClick={() => props.toggleWinePredictions(props.form)}>
            Show Wine Predictions
          </Button>
          <Collapse in={props.openWinePredictions}>
            <div>
              <div>
                {props.winePredictions.length > 0 ? (
                  props.winePredictions.map(prediction => (
                    <div key={prediction.wineName}>
                      {prediction.wineName}: {prediction.matches.join(", ")}
                    </div>
                  ))
                ) : (
                  <span>No predictions yet!</span>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      </Collapse>
    </div>
  );
};

/**
 *
 * @param {GlobalState} state
 * @param {*} ownProps
 */
const mapStateToProps = (state, ownProps) => ({
  openTastingGrid: state.display.openTastingGrid[ownProps.form],
  openWinePredictions: state.display.openWinePredictions[ownProps.form],
  winePredictions: getWinePredictions(state, ownProps.form),
});

export default connect(mapStateToProps, {
  toggleTastingGrid,
  toggleWinePredictions,
})(TastingGrid);
