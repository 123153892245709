import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import {
  dontHaveWine,
  toggleBlindPurchaseView,
} from "../../modules/blind-purchase/actions";
import { getRandomNeedsImprovementWine } from "../../modules/selectors";

// Changed to class so could do this.forceUpdate -- better to move the random wine to state
/**
 * @typedef Props
 * @property {Function} dontHaveWine
 * @property {Function} toggleBlindPurchaseView
 * @property {any} data
 * @property {any} dontHaveWines
 * @property {boolean} showServerView
 */

class BlindPurchase extends React.Component {
  /** @type {Props} props**/
  props;

  clickDontHaveWineButton(wineToDisplay) {
    this.props.dontHaveWine(wineToDisplay.name);
    this.forceUpdate();
  }
  render() {
    const props = this.props;
    const renderUserView = () => (
      <div>
        <h3>User View</h3>
        <p>
          Ask your server/bartender/wine store employee to hit the button below.
          We'll randomly select a wine that would be good for you to try blind.
          No peeking!
        </p>

        <p>
          <Button
            bsStyle="primary"
            onClick={() => props.toggleBlindPurchaseView()}
          >
            Server: Hit this button
          </Button>
        </p>
      </div>
    );

    const renderWineDisplay = wineToDisplay =>
      wineToDisplay ? (
        <div>
          <div>
            <label>Varietal:</label> {wineToDisplay.name}
          </div>
          <div>
            <label>Countries + Regions:</label>
            {wineToDisplay.countries
              .map(
                country =>
                  `${country.name}: ${country.regions
                    .map(region => region.name)
                    .join(", ")}`,
              )
              .map((countryInfo, index) => (
                <div key={`country-info-${index}`}>{countryInfo}</div>
              ))}
          </div>
        </div>
      ) : (
        <div>Sorry - no testable wines left to display!</div>
      );
    const renderServerView = () => {
      const wineToDisplay = getRandomNeedsImprovementWine(
        props.data || {},
        props.dontHaveWines,
      );
      return (
        <div>
          <h3>Server View</h3>
          <div>
            Hi! Your customer would like to blind taste a wine. Please pick a
            wine from one of the varietal-country combinations below, and pour a
            glass/wrap up a bottle{" "}
            <strong>
              without showing or telling the customer what you've selected.
            </strong>{" "}
            <hr />
            {renderWineDisplay(wineToDisplay)}
            <div>
              <Button
                onClick={() => this.clickDontHaveWineButton(wineToDisplay)}
              >
                {" "}
                {/* TODO: Better way to do this would be to store random wine in state and call an action creator here to update.*/}
                We don't have that wine.
              </Button>
            </div>
            <hr />
            Once you have a wine, please hit the button below and hand the phone
            back to your customer.{" "}
            <strong>Don't tell them what wine you picked!</strong> Thanks!
          </div>

          <p>
            <Button
              bsStyle="primary"
              onClick={() => props.toggleBlindPurchaseView()}
            >
              Server: OK
            </Button>
          </p>
        </div>
      );
    };
    return (
      <div>{props.showServerView ? renderServerView() : renderUserView()}</div>
    );
  }
}

const mapStateToProps = state => ({
  dontHaveWines: state.blindPurchase.dontHaveWines,
  showServerView: state.blindPurchase.showServerView,
});

const mapDispatchToProps = {
  dontHaveWine,
  toggleBlindPurchaseView,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlindPurchase);
