export const toggleBlindPurchaseView = () => ({
  type: "TOGGLE_BLIND_PURCHASE_VIEW",
});

export const dontHaveWine = wineName => ({
  payload: {
    wineName,
  },
  type: "DONT_HAVE_WINE",
});
