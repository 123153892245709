import BUTTON_STATE from "../../../helpers/button-state";

const initialState = {
  responseMessage: null,
  buttonState: BUTTON_STATE.NOTHING,
  isHistoryLoading: false,
  historyData: [],
  historyResponseMessage: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESULTS_SAVE_LOADING":
      return {
        ...state,
        buttonState: BUTTON_STATE.LOADING,
      };
    case "RESULTS_SAVE_COMPLETE":
      return {
        ...state,
        buttonState: action.payload.err
          ? BUTTON_STATE.ERROR
          : BUTTON_STATE.SUCCESS,
        responseMessage: action.payload.err
          ? JSON.stringify(action.payload.err)
          : null,
      };
    case "RESULTS_SAVE_RESET":
      return {
        ...state,
        buttonState: BUTTON_STATE.NOTHING,
        responseMessage: null,
      };
    case "GET_HISTORY_LOADING":
      return {
        ...state,
        isHistoryLoading: true,
      };
    case "GET_HISTORY_COMPLETE":
      return {
        ...state,
        historyData: action.payload.data && action.payload.data.Items,
        historyResponseMessage: action.payload.err,
        isHistoryLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
