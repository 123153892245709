import React from "react";
import { connect } from "react-redux";

import { eventFetchReset } from "../modules/db/event/actions";

const CodeInput = props => {
  const { onChange, ...inputProps } = props.input;
  const onEventCodeChange = e => {
    const newValue = e.target.value.toUpperCase().replace(/[^\w\d]/g, "");
    onChange(newValue);
    props.eventFetchReset();
  };

  return (
    <input
      {...inputProps}
      onChange={onEventCodeChange}
      className="form-control"
      maxLength="20"
    />
  );
};

export default connect(null, { eventFetchReset })(CodeInput);
