import { without } from "lodash";
import { isOldWorld } from "./country-helpers";

export const getValuesOrEmptyObject = obj =>
  obj && obj.values ? obj.values : {};

export const points = (object1, object2) => {
  if (!object1 || !object2) {
    return 0;
  }
  const keys = Object.keys(object1);
  const filteredKeys = without(keys, "observations"); //observations aren't stored
  let sum = filteredKeys.reduce((sum, key) => {
    return object1[key] &&
    object1[key] !== "other" && // Thinking this is the only 'other' check we need here to stop points for 'other' equaliing 'other'
      object2[key] &&
      object1[key].trim() === object2[key].trim()
      ? sum + 1
      : sum;
  }, 0);

  if (
    object2["country"] &&
    isOldWorld(object2["country"]) === isOldWorld(object1["country"])
  ) {
    sum = sum + 1;
  }

  return sum;
};

export const sumOfPoints = props => {
  let sum = 0;
  for (let i = 0; i < props.numberOfWines; i++) {
    const conclusions = props.conclusions || [];
    const results = props.results || [];
    sum =
      sum +
      points(
        getValuesOrEmptyObject(conclusions[i]),
        getValuesOrEmptyObject(results[i]),
      );
  }

  return sum;
};
