import React from "react";
import wineNames from "../../helpers/wine-names";
import TastingProgressBar from "./tasting-progress-bar";
import {
  allTestableCountryNames,
  getWineStats,
  getPercentageRight,
} from "../../modules/selectors";
import { ControlLabel } from "react-bootstrap";
import { isNaN } from "lodash";
import { isOldWorld } from "../../helpers/country-helpers";

const getStats = (data, key) => {
  return data ? getWineStats(data, key) : {};
};
const WineStats = props => {
  const renderVarietals = () => {
    const stats = getStats(props.data, "varietals");
    return wineNames.map(wine => (
      <div key={wine.name}>
        <label>{wine.name}</label>
        <TastingProgressBar
          numberRight={(stats[wine.name] && stats[wine.name].right) || 0}
          numberWrong={(stats[wine.name] && stats[wine.name].wrong) || 0}
        />
      </div>
    ));
  };

  const renderCountries = () => {
    const stats = getStats(props.data, "country");
    return allTestableCountryNames.map(countryName => (
      <div key={countryName}>
        <label>{countryName}</label>
        <TastingProgressBar
          numberRight={(stats[countryName] && stats[countryName].right) || 0}
          numberWrong={(stats[countryName] && stats[countryName].wrong) || 0}
        />
      </div>
    ));
  };

  const percentageStats = [
    {
      name: "Overall % Right (Varietal + Country)",
      keysToGetRight: ["country", "varietal"],
    },
    {
      name: "Old World vs. New World % Right",
      keysToGetRight: ["country"],
      transform: isOldWorld,
    },
    // { name: "Age % Right", keysToGetRight: ["age"] }, //Not implementing age right now...no one seems to care about
    { name: "Varietal % Right", keysToGetRight: ["varietals"] },
    { name: "Country % Right", keysToGetRight: ["country"] },
  ];

  return (
    <div>
      <h3>Stats</h3>
      {percentageStats.map(stat => {
        const percentRight = getPercentageRight(
          props.data,
          stat.keysToGetRight,
          stat.transform,
        );
        return (
          !isNaN(percentRight) && (
            <p key={stat.name}>
              <ControlLabel>{stat.name}:&nbsp;</ControlLabel>
              {percentRight}%
            </p>
          )
        );
      })}
      <h3>Varietals</h3>
      {renderVarietals()}
      <hr />
      <h3>Countries</h3>
      {renderCountries()}
    </div>
  );
};

export default WineStats;
