import { getCurrentUser } from "../../helpers/cognito-identity";

export const loginFailed = exception => ({
  type: "LOGIN_FAILED",
  payload: {
    exception,
  },
});

export const loginResponse = response => {
  const attributes = response.reduce((obj, attr) => {
    obj[attr.getName()] = attr.getValue();
    return obj;
  }, {});

  return {
    payload: {
      attributes,
    },
    type: "LOGIN_RESPONSE",
  };
};

export const skipLogin = () => ({
  type: "SKIP_LOGIN",
});

export const restoreUserFromStorage = () => async (dispatch, getState) => {
  try {
    const user = await getCurrentUser();
    user && dispatch(loginResponse(user));
  } catch (e) {
    dispatch(loginFailed(e));
  }
};
