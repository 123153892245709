import React from "react";
import { ControlLabel } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import EventCodeInput from "../helpers/event-code-input";

const LeaderboardCodeForm = props => {
  return (
    <form>
      <div>
        <ControlLabel>
          Enter a code to view the leaderboard for an event
        </ControlLabel>
        <Field
          name="code"
          className="form-control"
          component={EventCodeInput}
        />
      </div>
    </form>
  );
};

const ReduxLeaderboardCodeForm = reduxForm({
  form: "leaderboardCode",
  destroyOnUnmount: false,
})(LeaderboardCodeForm);

export default ReduxLeaderboardCodeForm;
