import {
  assignFieldIfPresent,
  getFieldsFromForm,
  getFieldsFromArray,
  createSaveToDbFunc,
} from "../util";
import { get } from "lodash";
import { EVENTS_TABLE_NAME } from "../../../helpers/constants";
import { getDynamoDb } from "../selectors";
import { push } from "react-router-redux";

const saveLoading = () => {
  return {
    type: "EVENT_SAVE_LOADING",
  };
};

export const eventSaveReset = () => {
  return {
    type: "EVENT_SAVE_RESET",
  };
};

const saveComplete = (err, data) => {
  return {
    type: "EVENT_SAVE_COMPLETE",
    payload: {
      err,
      data,
    },
  };
};

const createItem = state => {
  const Item = {};

  const fields = []
    .concat(getFieldsFromForm(get(state, "form.event"), "event"))
    .concat(getFieldsFromArray(get(state, "form.eventWines"), "event-wines"));

  fields.forEach(field =>
    assignFieldIfPresent(field.fieldName, field.obj, Item, field.fieldType),
  );

  return {
    Item,
    TableName: EVENTS_TABLE_NAME,
    ConditionExpression: "attribute_not_exists(event_eventCode)",
  };
};

export const saveEvent = createSaveToDbFunc(
  createItem,
  saveLoading,
  saveComplete,
);

export const fetchEventAnswers = async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const dynamoDb = getDynamoDb(dispatch, getState);

    var params = {
      ExpressionAttributeValues: {
        ":v1": {
          S: getState().form.general.values.eventCode,
        },
      },
      KeyConditionExpression: "event_eventCode = :v1",
      TableName: EVENTS_TABLE_NAME,
    };

    dynamoDb.query(params, function(err, data) {
      err ? reject(err) : resolve(data);
    });
  });
};

const mapRow = row => {
  return Object.keys(row).reduce((resultsArray, key) => {
    // sample format: event-wines_Wine0_region
    const regex = /event-wines_Wine(\d+)_(\w+)/g;
    const matches = regex.exec(key);
    if (matches && matches.length === 3) {
      const index = Number(matches[1]);
      const field = matches[2];
      !resultsArray[index] && resultsArray.push({ values: {} });
      resultsArray[index].values[field] = row[key].S;
    }

    return resultsArray;
  }, []);
};

export const eventFetchComplete = (results, err) => {
  return {
    type: "EVENT_FETCH_COMPLETE",
    payload: {
      results,
      err,
    },
  };
};

export const eventFetchReset = () => ({
  type: "EVENT_FETCH_RESET",
});

export const updateResultsFormState = dynamoEventData => (
  dispatch,
  getState,
) => {
  const results =
    dynamoEventData &&
    dynamoEventData.Items &&
    dynamoEventData.Items.length &&
    mapRow(dynamoEventData.Items[0]);

  if (results) {
    dispatch(eventFetchComplete(results));
    dispatch(push("/results"));
  } else {
    dispatch(
      eventFetchComplete(
        null,
        "No results found for that event code - please check the spelling.",
      ),
    );
  }
};

const fetchLoading = () => ({
  type: "EVENT_FETCH_LOADING",
});

export const getEventResults = () => {
  return async (dispatch, getState) => {
    dispatch(fetchLoading());
    try {
      const dynamoEventData = await fetchEventAnswers(dispatch, getState);
      dispatch(updateResultsFormState(dynamoEventData));
    } catch (e) {
      dispatch(eventFetchComplete(null, e));
    }
  };
};
