import React from "react";
import { connect } from "react-redux";
import { Nav, NavItem, Clearfix } from "react-bootstrap";
import { push } from "react-router-redux";
import { logout } from "../../helpers/cognito-auth";
import LoginButton from "../../helpers/login-button";

const links = [
  {
    name: "Entry",
    path: "/entry",
  },
  {
    name: "Results",
    path: "/results",
  },
  {
    name: "Analyze",
    path: "/analyze",
  },
  {
    name: "History",
    path: "/history",
  },
  {
    name: "Order",
    path: "/blind-purchase",
  },
];
const Header = props => (
  <header>
    <Nav
      bsStyle="tabs"
      activeKey={links.findIndex(obj => props.currentPath.includes(obj.path))}
      pullLeft={true}
    >
      {links.map((obj, index) => (
        <NavItem
          key={index}
          eventKey={index}
          onClick={() => props.changePage(obj.path)}
        >
          {obj.name}
        </NavItem>
      ))}
    </Nav>
    <Clearfix />
    <div className="topAndBottomMargin">
      {props.name && (
        <div className="bottomBorder">
          <span>Logged in as: {props.name} | </span>
          <button onClick={logout}>Logout</button>
        </div>
      )}
      {/* <AdvancedFieldsCheckboxForm /> */}
    </div>
    <LoginButton />
    {props.loginException && (
      <span>
        <strong>Error logging in - please try again:</strong>{" "}
        {props.loginException.message}
      </span>
    )}
  </header>
);

const mapStateToProps = state => ({
  name: state.auth.attributes && state.auth.attributes.email,
  currentPath: state.router.location.pathname,
  loginException: state.auth.loginException,
});

export default connect(mapStateToProps, {
  changePage: path => push(path),
})(Header);
