import AWS from "aws-sdk";
import { restoreUserFromStorage } from "../../modules/auth/actions";

export const getDynamoDb = async (dispatch, getState) => {
  if (new Date() > new Date(AWS.config.credentials.expireTime)) {
    await restoreUserFromStorage(dispatch, getState); // Will trigger refresh of access token
  }

  const dynamoDb = new AWS.DynamoDB({
    region: "us-east-1",
  });

  return dynamoDb;
};
