const initialState = {
  numberOfWines: 1,
  openTastingGrid: {},
  openWinePredictions: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_WINE":
      return {
        ...state,
        numberOfWines: state.numberOfWines + 1,
      };
    case "REMOVE_WINE":
      return {
        ...state,
        numberOfWines: state.numberOfWines - 1,
      };
    case "TOGGLE_TASTING_GRID":
      return {
        ...state,
        openTastingGrid: {
          ...state.openTastingGrid,
          [action.payload.formName]: !state.openTastingGrid[
            action.payload.formName
          ],
        },
      };
    case "TOGGLE_WINE_PREDICTIONS":
      return {
        ...state,
        openWinePredictions: {
          ...state.openWinePredictions,
          [action.payload.formName]: !state.openWinePredictions[
            action.payload.formName
          ],
        },
      };
    default:
      return state;
  }
};

export default reducer;
