import React from "react";
import { ProgressBar } from "react-bootstrap";

const TastingProgressBar = ({ numberRight, numberWrong }) => {
  const total = numberRight + numberWrong;

  // Show gray for what remains until we get up to 10 tastings. Do percentage of full bar after that.
  const scaledRight = total > 10 ? numberRight / total * 100 : numberRight * 10;
  const scaledWrong = total > 10 ? numberWrong / total * 100 : numberWrong * 10;

  return (
    <ProgressBar>
      <ProgressBar
        label={numberRight}
        bsStyle="success"
        now={scaledRight}
        key={1}
      />
      <ProgressBar
        label={numberWrong}
        bsStyle="danger"
        now={scaledWrong}
        key={2}
      />
    </ProgressBar>
  );
};

export default TastingProgressBar;
