import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { resetFormState } from "../../modules/form/actions";
import { resultsSaveReset } from "../../modules/db/results/actions";

const SaveSuccessModal = props => {
  const resetAndPush = pageName => () => {
    props.resultsSaveReset();
    props.resetFormState();
    props.push(pageName);
  };
  const onResetClick = resetAndPush("/entry");
  const onAnalyzeClick = resetAndPush("/analyze");

  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Results saved!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>What would you like to do next?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle="success"
          style={{ width: "inherit" }}
          onClick={onResetClick}
        >
          Taste more wines
        </Button>
        <Button
          style={{ width: "inherit" }}
          bsStyle="primary"
          onClick={onAnalyzeClick}
        >
          See how I'm doing
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {
  push,
  resetFormState,
  resultsSaveReset,
})(SaveSuccessModal);
