import React from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import {
  loginFailed,
  loginResponse,
  skipLogin,
  restoreUserFromStorage,
} from "../../modules/auth/actions";
import { parseUrl, login } from "../../helpers/cognito-auth";
import {
  restoreFormStateFromLocalStorage,
  saveFormStateToLocalStorage,
} from "../../modules/form/actions";

class Login extends React.Component {
  async performlogin() {
    try {
      this.props.saveFormStateToLocalStorage();
      await login();
    } catch (e) {
      this.props.loginFailed(e);
    }
  }
  async componentDidMount() {
    if (window.location.search) {
      try {
        await parseUrl();
        this.props.restoreFormStateFromLocalStorage();
        this.props.restoreUserFromStorage();
        this.props.changeToPageWithoutHash();
      } catch (e) {
        this.props.loginFailed(e);
      }
    } else {
      try {
        this.performlogin();
      } catch (e) {
        this.props.loginFailed(e);
      }
    }
  }

  render() {
    return <div>Logging in...</div>;
  }
}

const mapStateToProps = state => ({
  isLoggedIn: !!state.auth.attributes,
});

export default connect(mapStateToProps, {
  loginResponse,
  skipLogin,
  changeToPageWithoutHash: () => push("/entry"), //TODO: Could store last page in local storage and restore it to navigate to
  changePage: () => push("/entry"),
  restoreFormStateFromLocalStorage,
  saveFormStateToLocalStorage,
  restoreUserFromStorage,
  loginFailed,
})(Login);
