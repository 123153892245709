import React from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { ControlLabel } from "react-bootstrap";
import VarietalsAutocomplete from "../helpers/varietals-autocomplete";
import CountriesAutocomplete from "../helpers/countries-autocomplete";
import { connect } from "react-redux";

const HistoryFilterForm = props => (
  <form>
    <ControlLabel>Filter by Primary Varietal</ControlLabel>
    <VarietalsAutocomplete
      name="varietals"
      selectedVarietal={props.selectedVarietal}
    />
    <ControlLabel>Filter by Country</ControlLabel>
    <CountriesAutocomplete
      name="country"
      selectedVarietal={props.selectedVarietal}
      selectedCountry={props.selectedCountry}
    />
    <hr />
  </form>
);

const formName = "historyFilterForm";
const ConnectedHistoryFilterForm = reduxForm({
  destroyOnUnmount: false,
  form: formName,
})(HistoryFilterForm);

const ConnectedConclusionFormWithAddedProps = connect((state, ownProps) => {
  const selector = formValueSelector(formName);
  return {
    selectedVarietal: selector(state, "varietals"),
    selectedCountry: selector(state, "country"),
  };
})(ConnectedHistoryFilterForm);

export default ConnectedConclusionFormWithAddedProps;
