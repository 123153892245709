export const addWine = () => ({
  type: "ADD_WINE",
});

export const removeWine = () => ({
  type: "REMOVE_WINE",
});

export const toggleTastingGrid = formName => ({
  type: "TOGGLE_TASTING_GRID",
  payload: {
    formName,
  },
});

export const toggleWinePredictions = formName => ({
  type: "TOGGLE_WINE_PREDICTIONS",
  payload: {
    formName,
  },
});
