import React from "react";
import moment from "moment";
import { compact, get, flatMap, flatten, times } from "lodash";
import { MAX_NUMBER_OF_WINES } from "../../../helpers/constants";
import { isOldWorld } from "../../../helpers/country-helpers";
import { ControlLabel, Panel } from "react-bootstrap";
import HistoryFilterForm from "../../../forms/history-filter";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

const generatePerWineComparisonColumns = index => [
  {
    Header: "Style",
    accessor: `Wine${index}_country.S`,
    tranform: value => value && (isOldWorld(value) ? "Old World" : "New World"),
  },
  {
    Header: "Age",
    accessor: `Wine${index}_age.S`,
  },
  {
    Header: "Varietal",
    accessor: `Wine${index}_varietals.S`,
  },
  {
    Header: "Country",
    accessor: `Wine${index}_country.S`,
  },
  {
    Header: "Region",
    accessor: `Wine${index}_region.S`,
  },
];

const generatePerWineDisplayColumns = index => [
  {
    Header: "Observations",
    accessor: `conclusions_Wine${index}_observations.S`,
  },
  {
    Header: "Lessons Learned",
    accessor: `results_Wine${index}_observations.S`,
  },
];

const doesValueFit = (row, wineInRowIndex, selectedValue, columnSuffix) =>
  selectedValue
    ? selectedValue ===
        get(row, `conclusions_Wine${wineInRowIndex}_${columnSuffix}.S`) ||
      selectedValue ===
        get(row, `results_Wine${wineInRowIndex}_${columnSuffix}.S`)
    : true; // true if no selected country because should render all panels

const isWithinFilters = (
  selectedCountry,
  selectedVarietal,
  row,
  wineInRowIndex,
) => {
  const doesCountryFit = doesValueFit(
    row,
    wineInRowIndex,
    selectedCountry,
    "country",
  );
  const doesVarietalFit = doesValueFit(
    row,
    wineInRowIndex,
    selectedVarietal,
    "varietals",
  );

  return doesCountryFit && doesVarietalFit;
};

const renderPanels = (data, selectedCountry, selectedVarietal) => {
  const panels = flatMap(
    data.map((row, rowIndex) => {
      const columnsInEveryPanel = [
        {
          Header: "Date/Time",
          id: "datetime",
          accessor: row =>
            moment
              .unix(Number(row.TastingDateTime.N) / 1000)
              .format("MM/DD/YYYY h:mm a"),
        },
      ];

      return times(MAX_NUMBER_OF_WINES, wineInRowIndex => {
        const displayColumns = generatePerWineDisplayColumns(wineInRowIndex);
        const comparisonColumns = generatePerWineComparisonColumns(
          wineInRowIndex,
        );

        const displayValues = columnsInEveryPanel
          .concat(displayColumns)
          .reduce((obj, column) => {
            obj[column.Header] =
              typeof column.accessor === "function"
                ? column.accessor(row)
                : get(row, column.accessor);
            return obj;
          }, {});

        const getWithTransform = (row, column, prefix) => {
          const initialValue = get(row, `${prefix}${column.accessor}`);
          return column.tranform ? column.tranform(initialValue) : initialValue;
        };

        const values = comparisonColumns.reduce((obj, column) => {
          const conclusion = getWithTransform(row, column, "conclusions_");
          const result = getWithTransform(row, column, "results_");
          if (conclusion || result) {
            if (conclusion === result) {
              obj[column.Header] = (
                <span style={{ color: "green" }}>{conclusion}</span>
              );
            } else {
              obj[column.Header] = (
                <span>
                  <span
                    style={{ color: "red", textDecoration: "line-through" }}
                  >
                    {conclusion}
                  </span>
                  <span>&nbsp;{result}</span>
                </span>
              );
            }
          }
          return obj;
        }, displayValues);

        const headers = flatten([
          columnsInEveryPanel,
          comparisonColumns,
          displayColumns,
        ]).map(column => column.Header);

        // Using slice 1 below because date/time is always present but other fields might not be
        // In which we don't want to render the card
        if (
          Object.values(values)
            .slice(1)
            .some(value => !!value) &&
          // Filter to only country and varietal if selected
          isWithinFilters(
            selectedCountry,
            selectedVarietal,
            row,
            wineInRowIndex,
          )
        ) {
          return (
            <Panel key={`${rowIndex}-${wineInRowIndex}`}>
              <Panel.Body>
                {headers.map(
                  (header, columnIndex) =>
                    values[header] && (
                      <div key={columnIndex}>
                        <ControlLabel>{header}:</ControlLabel>
                        <span style={{ marginLeft: "5px" }}>
                          {values[header]}
                        </span>
                      </div>
                    ),
                )}
              </Panel.Body>
            </Panel>
          );
        }
      });
    }),
  );
  return compact(panels);
};

const HistoryTable = props => {
  if (props.data && props.data.length > 0) {
    return (
      <div>
        <HistoryFilterForm />
        {renderPanels(
          props.data,
          props.selectedCountry,
          props.selectedVarietal,
        )}
      </div>
    );
  }

  return <div>No history yet! Start tasting!</div>;
};

const ConnectedHistoryTable = connect((state, ownProps) => {
  const selector = formValueSelector("historyFilterForm");
  return {
    ...ownProps,
    selectedVarietal: selector(state, "varietals"),
    selectedCountry: selector(state, "country"),
  };
})(HistoryTable);

export default ConnectedHistoryTable;
