import React from "react";
import { connect } from "react-redux";
import { getHistory } from "../modules/db/results/actions";

class PageWithHistory extends React.Component {
  componentDidMount() {
    !this.props.isLoginSkipped && this.props.getHistory();
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isLoggedIn && nextProps.isLoggedIn) {
      !this.props.isLoginSkipped && this.props.getHistory();
    }
  }

  render() {
    if (this.props.isLoginSkipped) {
      return (
        <div>
          You need to <button onClick={this.props.goToLogin}>login</button> to analyze
          your results or view your history.
        </div>
      );
    }
    return (
      <div>
        <div>
          {this.props.isHistoryLoading ? (
            <span>Loading...</span>
          ) : (
            this.props.historyResponseMessage && (
              <span>{JSON.stringify(this.props.historyResponseMessage)}</span>
            )
          )}
          {this.props.historyData &&
            React.createElement(this.props.dataComponent, {
              data: this.props.historyData,
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  historyResponseMessage: state.db.results.historyResponseMessage,
  historyData: state.db.results.historyData,
  isHistoryLoading: state.db.results.isHistoryLoading,
  isLoginSkipped: state.auth.skipLogin,
  isLoggedIn: !!state.auth.attributes,
});

export default connect(mapStateToProps, {
  getHistory,
})(PageWithHistory);
