import React from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

const LoginButton = props =>
  !props.isLoggedIn && props.shouldShowButton ? (
    <Button bsStyle="success" onClick={props.goToLogin}>
      {props.text || "Login to save your results"}
    </Button>
  ) : null;

const mapStateToProps = state => {
  return {
    isLoggedIn: !!state.auth.attributes,
    shouldShowButton: !(state.router.location.pathname === "/login"), // Hacky - if we need to hide login on other pages refactor to a prop we pass in or some other approach
  };
};

const mapDispatchToProps = {
  goToLogin: () => push("/login"),
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
