export default [
  { name: "Italy", isOldWorld: true },
  { name: "Spain", isOldWorld: true },
  { name: "France", isOldWorld: true },
  { name: "USA", isOldWorld: false },
  { name: "China", isOldWorld: false },
  { name: "Argentina", isOldWorld: false },
  { name: "Chile", isOldWorld: false },
  { name: "Australia", isOldWorld: false },
  { name: "South Africa", isOldWorld: false },
  { name: "Germany", isOldWorld: true },
  { name: "Portugal", isOldWorld: true },
  { name: "Romania", isOldWorld: true },
  { name: "Greece", isOldWorld: true },
  { name: "Russia", isOldWorld: true },
  { name: "New Zealand", isOldWorld: false },
  { name: "Brazil", isOldWorld: false },
  { name: "Hungary", isOldWorld: true },
  { name: "Austria", isOldWorld: true },
  { name: "Serbia", isOldWorld: true },
  { name: "Moldova", isOldWorld: true },
  { name: "Bulgaria", isOldWorld: true },
  { name: "Georgia", isOldWorld: true },
  { name: "Switzerland", isOldWorld: true },
  { name: "Ukraine", isOldWorld: true },
  { name: "Japan", isOldWorld: false },
  { name: "Peru", isOldWorld: false },
  { name: "Uruguay", isOldWorld: true },
  { name: "Canada", isOldWorld: false },
  { name: "Algeria", isOldWorld: false },
  { name: "Czech Republic", isOldWorld: true },
  { name: "Macedonia", isOldWorld: true },
  { name: "Croatia", isOldWorld: true },
  { name: "Turkey", isOldWorld: true },
  { name: "Mexico", isOldWorld: false },
  { name: "Turkmenistan", isOldWorld: true }, //debatable?
  { name: "Morocco", isOldWorld: false },
  { name: "Uzbekistan", isOldWorld: true }, //?
  { name: "Slovakia", isOldWorld: true },
  { name: "Belarus" },
  { name: "Kazakhstan" },
  { name: "Tunisia" },
  { name: "Albania" },
  { name: "Montenegro" },
  { name: "Lebanon" },
  { name: "Slovenia" },
  { name: "Colombia" },
  { name: "Luxembourg" },
  { name: "Cuba" },
  { name: "Estonia" },
  { name: "Cyprus" },
  { name: "Azerbaijan" },
  { name: "Bolivia" },
  { name: "Madagascar" },
  { name: "Bosnia and Herzegovina" },
  { name: "Armenia" },
  { name: "Lithuania" },
  { name: "Egypt" },
  { name: "Israel" },
  { name: "Belgium" },
  { name: "Latvia" },
  { name: "Malta" },
  { name: "Zimbabwe" },
  { name: "Kyrgyzstan" },
  { name: "Paraguay" },
  { name: "Ethiopia" },
  { name: "Jordan" },
  { name: "United Kingdom" },
  { name: "Panama" },
  { name: "Tajikistan" },
  { name: "Liechtenstein" },
  { name: "Syria" },
  { name: "Reunion" },
];
