const initialState = {
  dontHaveWines: [],
  showServerView: false, //otherwise user view
  selectedRandomWine: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_BLIND_PURCHASE_VIEW":
      return {
        ...state,
        showServerView: !state.showServerView,
      };
    case "DONT_HAVE_WINE":
      return {
        ...state,
        dontHaveWines: state.dontHaveWines.concat(action.payload.wineName),
      };
    default:
      return state;
  }
};

export default reducer;
