import { formValueSelector } from "redux-form";
import { startCase } from "lodash";
import wineNames from "../helpers/wine-names";

const valueMatch = (value, tastingVal) => {
  if (typeof tastingVal === "function") {
    return tastingVal(value);
  } else if (tastingVal && tastingVal.length) {
    return tastingVal.includes(value);
  } else {
    return tastingVal === value;
  }
};

/**
 * Filter out obvious mismatches for red vs. white
 * @param {*} formValues
 * @param {*} tastingObj
 */
const redOrWhiteMatch = (formValues, tastingObj) => {
  const whiteColors = ["Watery", "Straw", "Yellow", "Gold"];
  const redColors = [
    "Pink",
    "Salmon",
    "Purple",
    "Ruby (Red)",
    "Garnet (Reddish-Brown)",
    "Brown",
  ];
  if (formValues && tastingObj && formValues.color && tastingObj.color) {
    if (
      (whiteColors.includes(formValues.color) &&
        redColors.includes(tastingObj.color)) ||
      (redColors.includes(formValues.color) &&
        whiteColors.includes(tastingObj.color))
    ) {
      return false;
    }
  }

  return true;
};

const calculateMatchScore = (formValues, tastingObj) => {
  return redOrWhiteMatch(formValues, tastingObj)
    ? Object.keys(formValues).reduce((matches, key) => {
        const valueMatched = valueMatch(formValues[key], tastingObj[key]);
        if (valueMatched) {
          matches.push(startCase(key));
        }
        return matches;
      }, [])
    : [];
};

/**
 *
 * @param {{[key: string]: string}} formValues
 * @param {{[key: string]: any}} tastingMap
 * @returns {WinePrediction[]} Array of matches that have scores greater than 0
 */
const getTotalMatches = (formValues, tastingMap) => {
  return Object.keys(tastingMap).reduce((prev, key) => {
    const matches = calculateMatchScore(formValues, tastingMap[key]);
    matches.length > 0 &&
      prev.push({
        wineName: key,
        matches: matches,
      });
    return prev;
  }, []);
};

const getWorldPredictions = values => {
  const newWorldTasting = {
    alcohol: ["Medium-Plus", "High"],
    aroma: value => value.includes("Fruit"),
    aromaIntensity: ["Medium-Plus", "High"],
  };

  const oldWorldTasting = {
    alcohol: ["Low", "Medium-Minus", "Medium"],
    aroma: value => value.includes("Earth"),
    aromaIntensity: ["Low", "Medium-Minus", "Medium"],
  };

  return getTotalMatches(values, {
    "New World": newWorldTasting,
    "Old World": oldWorldTasting,
  });
};

/**
 *
 * @param {GlobalState} state
 * @param {string} formName
 * @returns {WinePrediction[]}
 */
export const getWinePredictions = (state, formName) => {
  const selector = formValueSelector(formName);
  const formValues = selector(
    state,
    "alcohol",
    "aroma",
    "aromaIntensity",
    "body",
    "clarity",
    "color",
    "oak",
    "tannin",
    "sweetness",
  );
  const worldResults = formValues && getWorldPredictions(formValues);
  const varietalResults =
    formValues &&
    getTotalMatches(
      formValues,
      wineNames.reduce((obj, wine) => {
        if (wine.tasting) {
          obj[wine.name] = wine.tasting;
        }
        return obj;
      }, {}),
    );

  return worldResults.concat(varietalResults) || [];
};
